import React, { useEffect } from "react";
import { connect } from "react-redux";
import store from "../../redux/store";
import { setSearchResults } from "../../redux/actions/userActions";
import { Container, Row, Col } from "react-bootstrap";
import SearchResults from "../../components/SearchResults/SearchResults";
import RegisterForReports from "../Pages/Homepage/RegisterForReports/RegisterForReports";

const TextPage = props => {
  const { searchResults } = props.global;

  useEffect(() => {
    store.dispatch(setSearchResults([]));
  }, []);

  return (
    <>
      {searchResults.projects && searchResults.projects.length > 0 ? (
        <>
          <SearchResults searchResults={searchResults} />
          <RegisterForReports />
        </>
      ) : (
        <Container className="pb-5">
          <Row>
            <Col>
              <h1>How can we help?</h1>

              <p>
                Fraud Database can connect its users with law firms who can help
                them fight scammers and make sure they get their lost
                cryptocurrencies back.
              </p>

              <p>
                You can add your claim, register and monitor its process for
                free.
              </p>

              <p>We are collecting info regardless of your location.</p>

              <p>
                Fraud DB is providing communication platform and a great support
                system.
              </p>

              <p>
                We ensure that the highest possible level of fairness and
                transparency are observed.
              </p>

              <p>
                Members must contribute their own cases to receive benefit from
                the data shared by other members.
              </p>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  global: state.global,
});

export default connect(mapStateToProps)(TextPage);
