import React from "react";
import IconPending from "../../../../assets/images/icon-pending.svg";
import IconRejected from "../../../../assets/images/icon-rejected.svg";
import IconApproved from "../../../../assets/images/icon-approved.svg";
import { isMobileOnly } from "react-device-detect";
import moment from "moment";

const ProjectBar = props => {
  let userStatusIcon;
  let statusText;

  switch (props.projectData.status) {
    case "pending":
      userStatusIcon = IconPending;
      statusText = "Pending";
      break;
    case "rejected":
      userStatusIcon = IconRejected;
      statusText = "Rejected";
      break;
    default:
      userStatusIcon = IconApproved;
      statusText = "Approved";
  }

  return (
    <ul
      className={[
        !isMobileOnly ? "d-flex" : "",
        "justify-content-between list-unstyled highlightBar",
      ].join(" ")}
    >
      <li style={isMobileOnly ? { display: "block" } : {}}>
        <span className="statusFlag statusFlagBigger">
          <img src={userStatusIcon} alt="" /> {statusText}
        </span>
      </li>
      <li
        style={
          isMobileOnly
            ? { display: "block", paddingTop: "10px", paddingBottom: "10px" }
            : {}
        }
      >
        <small className="highlightBarCaption">PROJECT NAME</small>
        {props.projectData.projectName}
      </li>
      <li
        style={
          isMobileOnly
            ? { display: "block", paddingTop: "10px", paddingBottom: "10px" }
            : {}
        }
      >
        <small className="highlightBarCaption">URL</small>
        {props.projectData.projectURL}
      </li>
      <li
        style={
          isMobileOnly
            ? { display: "block", paddingTop: "10px", paddingBottom: "10px" }
            : {}
        }
      >
        <div className="highlightBarCaption">STATUS</div>
        <span style={{ textTransform: "uppercase" }}>
          {props.projectData.status}
        </span>
      </li>
      <li style={isMobileOnly ? { display: "block" } : {}}>
        <small className="highlightBarCaption">SUBMISSION DATE</small>
        {moment(props.projectData.timestamp * 1000).format(
          "YYYY/MM/DD HH:mm:ss"
        )}
      </li>
    </ul>
  );
};

export default ProjectBar;
