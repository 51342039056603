import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import store from "../../redux/store";
import { Button, FormControl, Form } from "react-bootstrap";
import classes from "./SearchBar.module.css";
import {
  clearSuccessErrorProps,
  searchProjects,
  setSearchResults,
} from "../../redux/actions/userActions";
import { NotificationManager } from "react-notifications";

const SearchBar = props => {
  const search = useRef("");

  useEffect(() => {
    if (props.success && props.success.projects) {
      store.dispatch(setSearchResults(props.success));

      if (Object.keys(props.success.projects).length === 0) {
        NotificationManager.warning("No projects found.");
      }

      clearSuccessErrorProps();
    }
  }, [props]);

  const handleSearch = e => {
    e.preventDefault();

    const val = search.current.value.trim();
    if (val.length > 2) {
      props.searchProjects(val);
    }
  };

  return (
    <Form inline className={classes.searchBar} onSubmit={e => handleSearch(e)}>
      <FormControl type="text" ref={search} placeholder="Search our database" />
      <Button variant="outline-primary" onClick={handleSearch}>
        GO
      </Button>
    </Form>
  );
};

SearchBar.propTypes = {
  searchProjects: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { searchProjects })(SearchBar);
