import axios from "axios";
import { API_URL } from "../../config/config";
import store from "../../redux/store";
import {
  GET_ERRORS,
  GET_SUCCESS,
  SET_SEARCH_RESULTS,
  SET_MENU_COUNTERS,
} from "./types";

// Get user data
export const getUser = email =>
  axios.post(API_URL + "/api/users/profile", { email });

// Get projects
export const getProjects = status =>
  axios.get(`${API_URL}/api/projects/getProjects/${status}`);

// Get Project data
export const getProject = id =>
  axios.get(API_URL + "/api/projects/getProject/" + id);

// Add project
export const addProject = data =>
  axios.post(API_URL + "/api/projects/addProject", data);

// Get Project updates
export const getProjectUpdates = id =>
  axios.get(API_URL + "/api/projects/getProjectUpdates/" + id);

// Get claims
export const getClaims = status =>
  axios.get(`${API_URL}/api/claims/getClaims/${status}`);

// Get claim
export const getClaim = claimId =>
  axios.get(`${API_URL}/api/claims/getClaim/${claimId}`);

// Search claims
export const searchProjects = data => dispatch => {
  return axios
    .post(`${API_URL}/api/claims/search`, { data })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Add claim
export const addClaim = data =>
  axios.post(API_URL + "/api/claims/addClaim", data);

// Update claim
export const updateClaim = data =>
  axios.post(API_URL + "/api/claims/updateClaim", data);

// Get sidebar counts
export const getSidebarCounts = () =>
  axios.get(API_URL + "/api/claims/getSidebarCounts/");

// Set new clients
export const setMenuCounters = counters => ({
  type: SET_MENU_COUNTERS,
  payload: counters,
});

// Set search results
export const setSearchResults = results => ({
  type: SET_SEARCH_RESULTS,
  payload: results,
});

// Get stats
export const getStats = () => axios.get(`${API_URL}/api/other/getStats`);

// Clear success/error props
export const clearSuccessErrorProps = () => {
  store.dispatch({
    type: GET_SUCCESS,
    payload: {},
  });
  store.dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};
