import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import SidebarCounter from "../SidebarCounter/SidebarCounter";

const SidebarUserLinks = props => {
  const [activeItem, setActiveItem] = useState("");
  const [open, setOpen] = useState({
    claims: false,
  });

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, [window.location.pathname]);

  const handleMenuItemClicked = destination => {
    setActiveItem(destination);
    if (isMobileOnly) {
      props.hideOnClick(false);
    }
  };

  const WrapperDiv = props => <div>{props.children}</div>;
  const Wrapper = isMobileOnly ? Collapse : WrapperDiv;

  const userStatus = props.auth.user.status;

  return (
    <>
      <Button
        variant="link"
        className="menuItemTitle"
        onClick={() =>
          setOpen(prevState => {
            return { ...prevState, claims: !open.claims };
          })
        }
        aria-controls="claims"
        aria-expanded={open.claims}
      >
        <span className={!isMobileOnly ? "text-uppercase" : ""}>Claims</span>
        {open.claims ? (
          isMobileOnly ? (
            <span className="float-right">&#x25B2;</span>
          ) : (
            ""
          )
        ) : isMobileOnly ? (
          <span className="float-right">&#x25BC;</span>
        ) : (
          ""
        )}
      </Button>
      <Wrapper in={open.claims}>
        <div id="claims" className={isMobileOnly ? "mobileNavLinks" : ""}>
          <Button
            as={Link}
            to={userStatus === "new" ? "/new-user" : "/claims/draft"}
            onClick={() => handleMenuItemClicked("/claims/draft")}
            className={[
              "menuItem py-2",
              activeItem === "/claims/draft" ||
              activeItem.includes("/claims/add") ||
              activeItem.includes("/claims/edit")
                ? "active"
                : null,
            ]}
          >
            Draft Claims
          </Button>
          <Button
            as={Link}
            to={userStatus === "new" ? "/new-user" : "/claims/submitted"}
            onClick={() => handleMenuItemClicked("/claims/submitted")}
            className={[
              "menuItem py-2",
              activeItem === "/claims/submitted" ? "active" : null,
            ]}
          >
            Submitted Claims
            {props.global.claims.pendingClaims > 0 ? (
              <SidebarCounter number={props.global.claims.pendingClaims} />
            ) : null}
          </Button>
          <Button
            as={Link}
            to={userStatus === "new" ? "/new-user" : "/claims/returned"}
            onClick={() => handleMenuItemClicked("/claims/returned")}
            className={[
              "menuItem py-2",
              activeItem === "/claims/returned" ? "active" : null,
            ]}
          >
            Returned Claims
            {props.global.claims.returnedClaims > 0 ? (
              <SidebarCounter number={props.global.claims.returnedClaims} />
            ) : null}
          </Button>
          <Button
            as={Link}
            to={userStatus === "new" ? "/new-user" : "/claims/approved"}
            onClick={() => handleMenuItemClicked("/claims/approved")}
            className={[
              "menuItem py-2",
              activeItem === "/claims/approved" ? "active" : null,
            ]}
          >
            Approved Claims
          </Button>
          <Button
            as={Link}
            to={userStatus === "new" ? "/new-user" : "/claims/rejected"}
            onClick={() => handleMenuItemClicked("/claims/rejected")}
            className={[
              "menuItem py-2",
              activeItem === "/claims/rejected" ? "active" : null,
            ]}
          >
            Rejected Claims
          </Button>

          <div className="menuItemTitle">PROJECTS</div>
          <Button
            as={Link}
            to="/projects/all"
            onClick={() => handleMenuItemClicked("/projects/all")}
            className={[
              "menuItem py-2",
              activeItem === "/projects/all" ? "active" : null,
            ]}
          >
            View All Projects
          </Button>
        </div>
      </Wrapper>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  global: state.global,
});

export default connect(mapStateToProps)(SidebarUserLinks);
