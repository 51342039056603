import React, { useEffect } from "react";
import { connect } from "react-redux";
import store from "../../../redux/store";
import { setSearchResults } from "../../../redux/actions/userActions";
import ReportFraud from "./ReportFraud/ReportFraud";
// import ThreeBoxes from "./ThreeBoxes/ThreeBoxes";
import HelpUs from "./HelpUs/HelpUs";
import RegisterForReports from "./RegisterForReports/RegisterForReports";
// import News from "./News/News";
import SearchResults from "../../../components/SearchResults/SearchResults";

const Homepage = props => {
  const { searchResults } = props.global;

  useEffect(() => {
    store.dispatch(setSearchResults([]));
  }, []);

  return (
    <div className="homepage">
      {searchResults.projects && searchResults.projects.length > 0 ? (
        <>
          <SearchResults searchResults={searchResults} />
          <RegisterForReports />
        </>
      ) : (
        <>
          {/*<News />*/}
          <ReportFraud />
          <HelpUs />
          <RegisterForReports />
          {/*<ThreeBoxes />*/}
          {/*<ThreeBoxes />*/}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  global: state.global,
});

export default connect(mapStateToProps)(Homepage);
