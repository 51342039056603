import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import classes from "./CookieConsent.module.css";

const CookieConsent = () => {
  const hideCookieConsent = () => {
    localStorage.setItem("cookieConsent", "1");
    window.location.reload();
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <Row className="justify-content-md-center">
          <Col md="9" lg="8" className="py-1">
            <p className={classes.p}>
              <strong>We value your privacy!</strong>
            </p>
            <p className={classes.p}>
              This website uses a technologies like Cookies or Targeting in
              order to personalize the advertising that you see. This helps us
              to show you more relevant ads and improves your internet
              experience. We also use it in order to measure results or align
              our website content. Because we value your privacy, we are
              herewith asking for your permission to use this technologies.
            </p>
          </Col>
          <Col md="3" lg="3">
            <Button className={classes.btn} onClick={hideCookieConsent}>
              OK
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CookieConsent;
