import React from "react";
import { Badge } from "react-bootstrap";

const SidebarCounter = props => (
  <Badge
    variant="light"
    pill
    className="badgeBg text-white font-weight-normal ml-2"
  >
    {props.number}
  </Badge>
);

export default SidebarCounter;
