import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { FaCheck } from "react-icons/fa";
import { sleep } from "../../../../utils/utils";

const Step = props => {
  useEffect(() => {
    async function proceed() {
      await sleep(5000);

      props.nextStep(1);
    }
    proceed().then(() => console.log("Email verified."));
  }, [props]);

  return (
    <>
      <h1 className={classes.h1}>Verify your email</h1>

      <Card className={classes.card}>
        <Card.Body className="verifiedEmail">
          <div className="circle success checkmarkAnimation">
            <FaCheck className="checkmark" />
          </div>

          <div className="messageText pt-5 text-center mb-5 pb-5">
            Email verified! Please wait...
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Step;
