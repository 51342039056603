import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { registerUser } from "../../../../redux/actions/authActions";
import Select from "react-select";
import { countryListReactSelect } from "../../../../utils/utils";
import privacyPolicy from "../../../../assets/documents/GDPR.pdf";
import termsAndConditions from "../../../../assets/documents/TermsOfUseFDB.pdf";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setErrors({});
    if (Object.keys(props.success).length > 0) {
      localStorage.setItem("userData", JSON.stringify(props.success));
      props.nextStep(1);
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleChange = (e, target) => {
    let name;
    let value;

    if (target === "country") {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setUserData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    let newUser = {
      step: 1,
      subStep: 2,
      name: userData.name,
      surname: userData.surname,
      email: userData.email,
      password: userData.password,
      password2: userData.password2,
      country: userData.country,
    };

    const introducerCode = window.location.pathname.replace("/register/", "");
    const introducer = introducerCode.length > 0;

    if (introducer) newUser.introducer = introducerCode;

    const confirm18YearsOld = document.getElementById("confirm18YearsOld")
      .checked;

    if (
      confirm18YearsOld ||
      (!confirm18YearsOld &&
        window.confirm(
          "I confirm that I am 18 years of age or older and I have read and accept the Terms of Service and Privacy Policy."
        ))
    ) {
      setDisableButton(true);
      props.registerUser(newUser);
    }
  };

  return (
    <>
      <h1 className={classes.h1}>Create your account</h1>

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label className={classes.label}>First name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  onChange={e => handleChange(e, "")}
                />
                <Form.Text style={{ color: "red" }}>{errors.name}</Form.Text>
              </Form.Group>

              <Form.Group as={Col} md="6" xs="12">
                <Form.Label className={classes.label}>Last name</Form.Label>
                <Form.Control
                  type="text"
                  name="surname"
                  required
                  onChange={e => handleChange(e, "")}
                />
                <Form.Text style={{ color: "red" }}>{errors.surname}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className={classes.label}>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  required
                  onChange={e => handleChange(e, "")}
                />
                <Form.Text style={{ color: "red" }}>{errors.email}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label className={classes.label}>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  required
                  onChange={e => handleChange(e, "")}
                />
                <Form.Text style={{ color: "red" }}>
                  {errors.password}
                  {errors.passwordincorrect}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} md="6" xs="12">
                <Form.Label className={classes.label}>
                  Confirm password
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password2"
                  required
                  onChange={e => handleChange(e, "")}
                />
                <Form.Text style={{ color: "red" }}>
                  {errors.password2}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className={classes.label}>
                  Country of residence
                </Form.Label>
                <Select
                  name="country"
                  required
                  onChange={e => handleChange(e, "country")}
                  options={countryListReactSelect(true)}
                />
                <Form.Text style={{ color: "red" }}>{errors.country}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                className="mt-1"
                style={{ maxWidth: "28px" }}
              >
                <Form.Check
                  custom
                  inline
                  label=""
                  type="checkbox"
                  id="confirm18YearsOld"
                />
              </Form.Group>
              <Form.Group as={Col} className="mt-1 mb-0">
                <label
                  className={classes.smallLabel}
                  htmlFor="confirm18YearsOld"
                >
                  I confirm that I am 18 years of age or older and I have read
                  and accept the{" "}
                  <Button
                    variant="link"
                    className="orangeLink"
                    href={termsAndConditions}
                    target="_blank"
                  >
                    Terms and Conditions
                  </Button>{" "}
                  and{" "}
                  <Button
                    variant="link"
                    className="orangeLink"
                    href={privacyPolicy}
                    target="_blank"
                  >
                    Privacy Policy
                  </Button>
                  .
                </label>
              </Form.Group>
            </Form.Row>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              type="submit"
              className="orangeButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  registerUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(Step);
