import { SET_SEARCH_RESULTS } from "../actions/types";
import { SET_MENU_COUNTERS } from "../actions/types";

const initialState = {
  searchResults: {},
  claims: {},
};

export default function (state = initialState, action) {
  if (action.type === SET_SEARCH_RESULTS) {
    return {
      ...state,
      searchResults: action.payload,
    };
  } else if (action.type === SET_MENU_COUNTERS) {
    return {
      ...state,
      claims: action.payload,
    };
  } else {
    return state;
  }
}
