import React, { useEffect } from "react";
import { connect } from "react-redux";
import store from "../../redux/store";
import { setSearchResults } from "../../redux/actions/userActions";
import { Container, Row, Col } from "react-bootstrap";
import SearchResults from "../../components/SearchResults/SearchResults";
import RegisterForReports from "../Pages/Homepage/RegisterForReports/RegisterForReports";

const TextPage = props => {
  const { searchResults } = props.global;

  useEffect(() => {
    store.dispatch(setSearchResults([]));
  }, []);

  return (
    <>
      {searchResults.projects && searchResults.projects.length > 0 ? (
        <>
          <SearchResults searchResults={searchResults} />
          <RegisterForReports />
        </>
      ) : (
        <Container className="pb-5">
          <Row>
            <Col>
              <h1>FAQ</h1>

              <h4>What is ICIFDB (FRAUD DB)?</h4>
              <p>
                Fraud Database is a new service developed by Diligio company
                which provides a platform for people who have been scammed and
                robbed off their cryptocurrencies. Fraud DB is a platform which
                can help you regain your stolen coins.
              </p>

              <h4>What can I submit to Fraud DB?</h4>
              <p>
                One can submit a claim for a project they believe is a scam or
                suspicious. All the people who lost money to scammers can submit
                their reports into our database so we can help them get their
                crypto back.
              </p>

              <h4>How do I submit? (description of the process)</h4>
              <p>
                Click on “Register” located in the top right corner of the Fraud
                DB site and create your account, enter your “contact details”
                and your “date of birth”. After finishing previous steps proceed
                to “Trading experience”. Carefully read “Claim disclosure” and
                “Declaration” and check the box below if you agree with the
                terms. After finishing this process please login and click on
                “Add claim”, fill out the form and submit for review.
              </p>

              <h4>Can I get legal help?</h4>
              <p>
                Yes, you can get legal help through Fraud DB platform. After
                submitting your claim, you can decide if you want the help of
                our lawyers since they will be able to see your submitted
                claims.
              </p>

              <h4>Do I need to pay?</h4>
              <p>No, you can register and submit your claim for free.</p>

              <h4>What Countries are supported?</h4>
              <p>You can submit your claim from any country in the world.</p>

              <h4>
                What if I don’t have all of the information about the scam
                project?
              </h4>
              <p>
                You can submit the information you have, and our Fraud DB team
                will review it. If necessary, we will contact you with a request
                for additional details or explanations.
              </p>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  global: state.global,
});

export default connect(mapStateToProps)(TextPage);
