import React, { useState } from "react";
import { Button, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { FiLogOut } from "react-icons/fi";
import { getCategoryName } from "../../utils/utils";
import { isMobileOnly } from "react-device-detect";
import SidebarUserLinks from "./SidebarUserLinks";
import logo from "../../assets/images/FDB_logo_new.svg";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import StatsBar from "../StatsBar/StatsBar";
// import { userRoles } from "../../config/constants";

const MyNavbar = props => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const isRegisterPage = window.location.href.includes("register");
  if (props.auth.user.continueRegistration && !isRegisterPage) {
    console.log("continueRegistration");
    window.location.href = "/register";
    return false;
  }

  // const isHomePage = window.location.pathname === "/";
  const isLoginPage = window.location.href.includes("login");
  const isEmailConfirmPage = window.location.href.includes("email/confirm");
  // const isForgotPasswordPage = window.location.href.includes("forgot-password");
  // const isResetPasswordPage = window.location.href.includes("resetpassword");

  let category = getCategoryName();

  let logoutBtn = null;
  let loginRegister = null;
  if (props.auth.isAuthenticated) {
    logoutBtn = (
      <span
        id="logoutBtn"
        className="ml-3 mt-1"
        onClick={props.logoutClicked}
        style={{ cursor: "pointer" }}
      >
        <FiLogOut />
      </span>
    );
  } else {
    if (!isLoginPage && !isRegisterPage && !isEmailConfirmPage)
      return <StatsBar />;
  }

  const handleNavbarToggle = e => {
    setIsNavbarOpen(e);
  };

  return logoutBtn ? (
    <Navbar
      expand="lg"
      variant="light"
      onToggle={e => handleNavbarToggle(e)}
      expanded={isNavbarOpen}
      className={isMobileOnly && isNavbarOpen ? "mobileNavbar" : null}
    >
      {!isRegisterPage ? (
        <>
          {isMobileOnly && !isNavbarOpen ? (
            <Navbar.Brand as={Link} className="py-0" to="/">
              <img
                src={logo}
                alt="icifdb.org"
                style={{ display: "block", width: "135px" }}
              />
            </Navbar.Brand>
          ) : null}

          {isMobileOnly && !isNavbarOpen ? (
            <span className="headerCategory ml-md-4">{category}</span>
          ) : null}

          {!isMobileOnly &&
          props.auth.user.name !== undefined &&
          props.auth.user.name.length > 0 ? (
            <div className="w-100 d-flex justify-content-end">
              {/*<span className="headerCircle" />*/}
              <div className="ml-2 mr-3 mt-1">
                {props.auth.user.name} {props.auth.user.surname}
              </div>
              {logoutBtn}
            </div>
          ) : null}

          {!isLoginPage ? loginRegister : null}
          {isMobileOnly ? (
            <>
              <div
                className={[
                  "d-flex",
                  isMobileOnly && !isNavbarOpen
                    ? "justify-content-end"
                    : "w-100 justify-content-between",
                  isNavbarOpen ? "py-3 mb-4" : "",
                ].join(" ")}
                style={
                  isNavbarOpen ? { borderBottom: "1px solid #B0B0B0" } : {}
                }
              >
                {isMobileOnly && isNavbarOpen ? (
                  <>
                    <div className="ml-1 mt-2 text-white">
                      {/*<span className="headerCircle" />*/}
                      {props.auth.user.name} {props.auth.user.surname}
                    </div>
                    <IoIosClose
                      className="mobileNavbarClose"
                      onClick={() => handleNavbarToggle(false)}
                    />
                  </>
                ) : (
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                )}
              </div>
              <Navbar.Collapse id="basic-navbar-nav">
                <SidebarUserLinks hideOnClick={handleNavbarToggle} />

                {isNavbarOpen ? (
                  <Button
                    variant="link"
                    className="menuItemTitle"
                    onClick={props.logoutClicked}
                  >
                    Log Out
                  </Button>
                ) : null}
              </Navbar.Collapse>
            </>
          ) : null}
        </>
      ) : (
        <div className="w-100 d-flex justify-content-end">
          <div className="ml-2 mr-3 mt-1">
            {props.auth.user.name} {props.auth.user.surname}
          </div>
          {logoutBtn}
        </div>
      )}
    </Navbar>
  ) : null;
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MyNavbar);
