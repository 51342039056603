import React from "react";
import { connect } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Container, Row, Col } from "react-bootstrap";
import { cutLongText, numberFormat } from "../../utils/utils";

const SearchResults = props => {
  const { searchResults } = props.global;
  let columns = [];
  let searchResultsTable = [];

  const style = {
    borderBottom: "1px solid var(--color-blue2)",
    paddingTop: "10px",
    paddingBottom: "20px",
    marginBottom: "10px",
  };
  const btnStyle = {
    width: "100%",
    height: "45px",
  };
  if (isMobileOnly) {
    btnStyle["fontSize"] = "0.9rem";
  }

  if (!isMobileOnly) {
    columns = [
      {
        dataField: "projectName",
        text: "Project Name",
        headerStyle: (column, colIndex) => {
          return { width: "200px" };
        },
      },
      {
        dataField: "lostFunds",
        text: "Lost Funds",
        classes: "text-right",
        headerStyle: (column, colIndex) => {
          return { width: "150px", textAlign: "right" };
        },
      },
      {
        dataField: "projectDescriptionCut",
        text: "Project Description",
      },
      {
        dataField: "scamDescriptionCut",
        text: "Scam Description",
      },
      {
        dataField: "details",
        text: "",
        headerStyle: (column, colIndex) => {
          return { width: "135px" };
        },
      },
    ];
  }

  if (searchResults.projects && searchResults.projects.length > 0) {
    searchResults.projects.map((keyName, keyIndex) => {
      // Some data formatting before displaying
      searchResults.projects[keyIndex].lostFunds = searchResults.totalLoss[
        searchResults.projects[keyIndex]._id
      ]
        ? `$ ${numberFormat(
            searchResults.totalLoss[searchResults.projects[keyIndex]._id],
            2,
            ".",
            ","
          )}`
        : "N/A";

      searchResults.projects[keyIndex].projectDescriptionCut = cutLongText(
        searchResults.projects[keyIndex].projectDescription,
        200
      );

      searchResults.projects[keyIndex].scamDescriptionCut = cutLongText(
        searchResults.projects[keyIndex].scamDescription,
        200
      );

      searchResults.projects[keyIndex].details = (
        <Button
          onClick={() => (window.location.href = "/register")}
          className={[
            "orangeButton py-1 m-0",
            isMobileOnly ? "text-uppercase" : null,
          ].join(" ")}
          style={btnStyle}
        >
          Register for full details
        </Button>
      );

      return searchResultsTable.push(searchResults.projects[keyIndex]);
    });
  }

  return (
    <Container fluid>
      <Container className="maxPageWidth" fluid>
        <Row>
          {!isMobileOnly ? (
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={searchResultsTable}
              columns={columns}
              bordered={false}
              headerClasses="tableHeaderClassClear"
            />
          ) : (
            searchResultsTable.map((val, idx) => (
              <Col key={idx} style={style} lg={12}>
                <span
                  style={{ color: "var(--color-green4)", fontWeight: "bold" }}
                >
                  Project Name:
                </span>
                <br />
                <span style={{ fontWeight: "500" }}>
                  {searchResultsTable[idx].projectName}
                </span>
                <br />
                <br />
                <span
                  style={{ color: "var(--color-green4)", fontWeight: "bold" }}
                >
                  Lost Funds:
                </span>{" "}
                {searchResultsTable[idx].lostFunds}
                <br />
                <br />
                <span
                  style={{ color: "var(--color-green4)", fontWeight: "bold" }}
                >
                  Project Description:
                </span>
                <br />
                {searchResultsTable[idx].projectDescription}
                <br />
                <br />
                <span
                  style={{ color: "var(--color-green4)", fontWeight: "bold" }}
                >
                  Scam Description:
                </span>
                <br />
                {searchResultsTable[idx].scamDescription}
                <br />
                <br />
                {searchResultsTable[idx].details}
              </Col>
            ))
          )}
        </Row>
      </Container>
    </Container>
  );
};

const mapStateToProps = state => ({
  global: state.global,
});

export default connect(mapStateToProps)(SearchResults);
