import React from "react";
// import { connect } from "react-redux";
// import { FaChartLine, FaAddressCard, FaPassport, FaMoneyBillAlt, FaExchangeAlt, FaDownload } from "react-icons/fa";
// import classes from "./Sidebar.module.css";
import logo from "../../assets/images/FDB_logo_new.svg";
import SidebarUserLinks from "./SidebarUserLinks";

const SidebarUser = () => {
  return (
    <div className="sidebarUser">
      <img
        src={logo}
        className="sidebarLogo sidebarLogoAdmin"
        alt="icifdb.org"
      />

      <SidebarUserLinks />
    </div>
  );
};

export default SidebarUser;
