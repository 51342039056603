import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { resendVerificationEmail } from "../../../../redux/actions/authActions";
import emailIcon from "../../../../assets/images/email.svg";
import { clearLocalStorage } from "../../../../utils/utils";
import { isIOS, isFirefox } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    // Set temporary userData to store ID and email
    const userData = JSON.parse(localStorage.userData);
    setUserData({
      userId: userData._id,
      email: userData.email,
    });

    if (isIOS || isFirefox) {
      alert("We are checking whether your email was successfully verified.");
    }

    // Remove registration steps
    if (
      (props.auth.user.showVerifyEmail === undefined &&
        userData.showVerifyEmail) ||
      props.auth.user.showVerifyEmail
    ) {
      console.log("CLEARING localStorage at email sent");
      clearLocalStorage();
      localStorage.removeItem("jwtToken");
    }
  }, [props.auth.user.showVerifyEmail]);

  const handleResendEmail = () => {
    if (window.confirm("Press OK to send the verification email again.")) {
      props.resendVerificationEmail(userData.userId, userData.email);
    }
  };

  return (
    <>
      <h1 className={classes.h1}>Verify your email</h1>

      <Card className={classes.card}>
        <Card.Body>
          <img className={classes.emailIcon} src={emailIcon} alt="Email" />

          <p className={classes.emailText}>
            We sent a verification email to{" "}
            <strong>{userData.email || "email"}</strong>. Click the link inside
            to proceed to the next step!
          </p>

          <Button
            variant="link"
            className={classes.emailResend}
            onClick={() => handleResendEmail()}
          >
            Email didn't arrive?
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  resendVerificationEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { resendVerificationEmail })(Step);
