import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetPassword } from "../../../redux/actions/authActions";
import { Form, Button, Spinner, Card } from "react-bootstrap";
import classes from "../Login/Login.module.css";
import { isMobileOnly } from "react-device-detect";
import logo from "../../../assets/images/FDB_logo_new.svg";

const ForgotPassword = props => {
  const [email, setEmail] = useState(true);
  const [showEmailField, setShowEmailField] = useState(true);
  const [disableForgotPassBtn, setDisableForgotPassBtn] = useState(false);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      // window.location.reload();
      setShowEmailField(false);
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableForgotPassBtn(false);
    }
  }, [props]);

  const handleChange = e => {
    setEmail(e.target.value);
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity()) {
      setDisableForgotPassBtn(true);

      props.resetPassword(email);
    }
  };

  return (
    <div
      className="h-100 d-flex align-items-center justify-content-center"
      style={
        !isMobileOnly ? { backgroundColor: "var(--color-light-gray5)" } : null
      }
    >
      <Card className={classes.card}>
        <Card.Body>
          <div style={{ height: "105px" }}>
            <img
              src={logo}
              className={classes.loginLogo}
              alt="icifdb.org"
              onClick={() => (window.location.href = "/")}
            />
          </div>

          {showEmailField ? (
            <Form onSubmit={e => handleSubmit(e)}>
              <Form.Group controlId="validationEmail">
                <Form.Label className={classes.label}>Enter email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  required
                  onChange={e => handleChange(e)}
                />
              </Form.Group>

              <Form.Text style={{ color: "red" }}>
                {errors.server}
                {errors.email}
                <br />
              </Form.Text>

              <Button
                type="button"
                className="blueButton mt-0 float-left"
                style={{ width: "130px" }}
                onClick={() => (window.location.href = "/login")}
              >
                BACK TO LOGIN
              </Button>
              <Button
                type="submit"
                className="blueButton mt-0 float-right"
                disabled={disableForgotPassBtn}
              >
                {disableForgotPassBtn ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    RESET
                  </>
                ) : (
                  "RESET"
                )}
              </Button>
            </Form>
          ) : (
            <Form.Text className="text-center text-success">
              Please check your email.
              <br />
              <br />
              <br />
            </Form.Text>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { resetPassword })(ForgotPassword);
