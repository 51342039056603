import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classes from "./Dashboard.module.css";
import { getProject } from "../../../../redux/actions/userActions";
import { Card } from "react-bootstrap";

const ProjectData = props => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getProject(props.projectId)
      .then(res => {
        setData(res.data);
      })
      .catch(err => console.log(err));
  }, [props.projectId]);

  return (
    <Card className={classes.card}>
      <Card.Body className="p-0">
        <p
          className={[
            "p-3 m-0 border-bottom fontWeight500",
            classes.cardHeader,
          ].join(" ")}
        >
          Project Information
        </p>

        {data ? (
          <>
            <p className="font-weight-bold mt-3 px-3">Project Description:</p>
            <p className="px-4">{data.projectDescription}</p>
            <p className="font-weight-bold px-3">Scam Description:</p>
            <p className="px-4">{data.scamDescription}</p>
            <p className="font-weight-bold px-3">Comment:</p>
            <p className="px-4">{data.comment}</p>
          </>
        ) : null}

        {data && data.employmentIncome ? (
          <>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Employment Status:{" "}
              <strong>{data.employmentIncome.employmentStatus}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Industry: <strong>{data.employmentIncome.industry}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Level of education:{" "}
              <strong>{data.employmentIncome.levelOfEducation}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Annual income:{" "}
              <strong>{data.employmentIncome.annualSalary}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Value of savings and investments:{" "}
              <strong>{data.employmentIncome.savings}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Source of wealth:{" "}
              <strong>{data.employmentIncome.wealthSource}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              How much do you expect to deposit in next 12 months?{" "}
              <strong>{data.employmentIncome.expectedDeposit}</strong>
            </p>
          </>
        ) : null}

        {data && data.tradingExperience ? (
          <>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Have you traded Forex or CFDs before?{" "}
              <strong>{data.tradingExperience.experience}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Do you fully understand the risks associated when trading
              leveraged Forex and CFDs products?{" "}
              <strong>{data.tradingExperience.understandTradingRisks}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              You understand that trading Forex and CFD based products can lead
              to losing more then your invested capital?{" "}
              <strong>{data.tradingExperience.understandTradingLoss}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              What is the reason for you to open this trading account?{" "}
              <strong>{data.tradingExperience.reasonForOpeningAccount}</strong>
            </p>
            {/*<p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>*/}
            {/*  When trading leveraged products, what tool can you use to prevent*/}
            {/*  excess losses?{" "}*/}
            {/*  <strong>{data.tradingExperience.toolsToPreventExcessLoss}</strong>*/}
            {/*</p>*/}
          </>
        ) : null}

        {data && data.tradingPreferences ? (
          <>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Please choose the base currency of your trading account:{" "}
              <strong>{data.tradingPreferences.baseAccountCurrency}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Trading platform:{" "}
              <strong>{data.tradingPreferences.accountPlatform}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Account type:{" "}
              <strong>{data.tradingPreferences.accountType}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 ml-3"].join(" ")}>
              Leverage: <strong>1:{data.tradingPreferences.leverage}</strong>
            </p>
          </>
        ) : null}
      </Card.Body>
    </Card>
  );
};

ProjectData.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(ProjectData);
