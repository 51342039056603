import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classes from "./Dashboard.module.css";
import { getProjectUpdates } from "../../../../redux/actions/userActions";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Card } from "react-bootstrap";

const ProjectUpdate = props => {
  const [updates, setUpdates] = useState({});

  useEffect(() => {
    triggerGetProjectUpdates();
  }, [props.projectId]);

  const triggerGetProjectUpdates = () => {
    getProjectUpdates(props.projectId)
      .then(res => {
        setUpdates(res.data);
      })
      .catch(err => console.log(err));
  };

  const columnsProjectUpdate = [
    {
      dataField: "timestamp",
      text: "DATE",
      sort: true,
      classes: "breakWord",
      headerStyle: (column, colIndex) => {
        return { width: "110px" };
      },
    },
    {
      dataField: "message",
      text: "UPDATE",
      classes: "breakWord",
    },
    {
      dataField: "actionedBy",
      text: "ACTIONED BY",
      sort: true,
      classes: "breakWord",
      headerStyle: (column, colIndex) => {
        return { width: "110px" };
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
    ],
  });

  let dataProjectUpdate = [];
  if (!!updates) {
    for (let i = 0; i < updates.length; i++) {
      if (Number.isInteger(updates[i].timestamp)) {
        updates[i].timestamp = moment(updates[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (updates[i].customName) updates[i].actionedBy = updates[i].customName;

      dataProjectUpdate.push(updates[i]);
    }
  }

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0 pb-2">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Project Updates
          </p>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={dataProjectUpdate}
            columns={columnsProjectUpdate}
            defaultSorted={defaultSorted}
            hover={true}
            pagination={pagination}
            classes={classes.table}
            headerClasses={classes.tableHeaderClass}
          />
        </Card.Body>
      </Card>
    </>
  );
};

ProjectUpdate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(ProjectUpdate);
