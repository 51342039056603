import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";
import classes from "./Layout.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { disableSidebarNavbarPaths } from "../../config/constants";
import NavbarTop from "../../components/Navigation/Navbar";
import SidebarUser from "../../components/Navigation/SidebarUser";
import SidebarRegister from "../../components/Navigation/SidebarRegister";
// import AdBar from "../../components/AdBar/AdBar";
import NavBar from "../../components/NavBar/NavBar";
import StatsBar from "../../components/StatsBar/StatsBar";
import Footer from "../../components/Footer/FooterHomepage";
import CookieConsent from "../../components/CookieConsent/CookieConsent";
import { NotificationContainer } from "react-notifications";

const Layout = props => {
  const loginOrRegister =
    window.location.href.includes("login") ||
    window.location.href.includes("register") ||
    window.location.href.includes("email/confirm");

  const onLogoutClick = e => {
    e.preventDefault();
    props.logoutUser();
    window.location.href = "/login";
  };

  const HeaderWithRouter = withRouter(NavbarTop);

  let Sidebar =
    props.auth.isAuthenticated &&
    props.auth.user.registered &&
    !isMobileOnly &&
    !disableSidebarNavbarPaths.includes(window.location.pathname)
      ? withRouter(SidebarUser)
      : null;

  if (window.location.href.includes("register")) {
    Sidebar = !isMobileOnly ? SidebarRegister : null;
  }

  return (
    <>
      {disableSidebarNavbarPaths.includes(window.location.pathname) &&
      !loginOrRegister ? (
        <>
          {/*<AdBar />*/}
          <StatsBar />
          <NavBar />
        </>
      ) : null}

      <Container
        style={
          !disableSidebarNavbarPaths.includes(window.location.pathname) ||
          loginOrRegister
            ? { height: "100vh" }
            : {}
        }
        fluid
      >
        <Row className="h-100">
          {Sidebar ? (
            <Col
              className={[
                "sidebar px-0",
                window.location.href.includes("register") ? "register" : "",
              ].join(" ")}
            >
              <Sidebar />
            </Col>
          ) : null}
          <Col
            className={[
              classes.Content,
              !props.auth.isAuthenticated || window.location.pathname === "/"
                ? "mx-auto"
                : "",
            ].join(" ")}
          >
            {!disableSidebarNavbarPaths.includes(window.location.pathname) ||
            window.location.href.includes("register") ? (
              <HeaderWithRouter logoutClicked={onLogoutClick} />
            ) : null}
            {props.children}
          </Col>
        </Row>
      </Container>

      {disableSidebarNavbarPaths.includes(window.location.pathname) &&
      !loginOrRegister ? (
        <>
          <Container className="mt-auto p-0" fluid>
            <Row className="no-gutters">
              <Col>
                <Footer />
              </Col>
            </Row>
          </Container>

          {/*<Modal show={showModal} onHide={handleModalClose} size="md">*/}
          {/*  <Modal.Header closeButton>*/}
          {/*    <Modal.Title>FraudDB</Modal.Title>*/}
          {/*  </Modal.Header>*/}
          {/*  <Modal.Body className="p-5">*/}
          {/*    <p>*/}
          {/*      ICIFDB is near completion but it is not open for public yet.*/}
          {/*      Please stay tuned and come back in few days.*/}
          {/*    </p>*/}
          {/*  </Modal.Body>*/}
          {/*</Modal>*/}
        </>
      ) : null}

      {disableSidebarNavbarPaths.includes(window.location.pathname) &&
      !localStorage.cookieConsent ? (
        <CookieConsent />
      ) : null}

      <NotificationContainer />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Layout);
