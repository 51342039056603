import React from "react";
import { Container, Col, Row, Navbar, Nav } from "react-bootstrap";
import classes from "./NavBar.module.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/FDB_logo_new.svg";
import SearchBar from "../SearchBar/SearchBar";

const NavBar = () => {
  return (
    <Container className={classes.navBar} fluid>
      <Container className="maxPageWidth" fluid>
        <Row>
          <Col className="px-0 py-2">
            <Navbar variant="dark" expand="lg" className="px-0">
              <Navbar.Brand
                onClick={() => (window.location.href = "/")}
                style={{ cursor: "pointer" }}
              >
                <img src={logo} alt="FraudDB" />
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link
                    as={Link}
                    to="/about-us"
                    className={classes.navbarLink}
                  >
                    ABOUT US
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/mission"
                    className={classes.navbarLink}
                  >
                    MISSION
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/how-can-we-help"
                    className={classes.navbarLink}
                  >
                    HOW CAN WE HELP
                  </Nav.Link>
                  <Nav.Link as={Link} to="/faq" className={classes.navbarLink}>
                    FAQ
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>

              <SearchBar />
            </Navbar>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default NavBar;
