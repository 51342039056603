import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API_URL } from "../../../config/config";
import {
  getProjects,
  addProject,
  getClaim,
  addClaim,
  updateClaim,
} from "../../../redux/actions/userActions";
import {
  countryListReactSelect,
  getClaimTypesSelectListOptions,
} from "../../../utils/utils";
import Addresses from "../../../components/Addresses/Addresses";
import { countryList } from "../../../config/constants";
import { isMobileOnly } from "react-device-detect";
import {
  Col,
  Container,
  Row,
  Modal,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FileIcons from "../../../components/FileIcons/FileIcons";
import { NotificationManager } from "react-notifications";

// Register FilePond plugin
registerPlugin(FilePondPluginFileRename, FilePondPluginImagePreview);

const Claims = ({ history, ...props }) => {
  const [state, setState] = useState({
    errors: {},
    showProjectModal: false,
    showProjectDataModal: false,
    disableAddEditClaimBtn: false,
    disableAddProjectBtn: false,
  });
  const [claim, setClaim] = useState({});
  const [projectData, setProjectData] = useState({}); // store add new project data
  const [projectDetails, setProjectDetails] = useState({}); // all project fields get by projectId as a key
  const [projectNames, setProjectNames] = useState({}); // react-select values get by projectId as a key
  const [projectList, setProjectList] = useState([]); // react-select values to show in dropdown
  const [addressesTable, setAddressesTable] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [submitToReview, setSubmitToReview] = useState(false);
  const [modified, setModified] = useState(false);
  const [autoSaveTrigger, setAutoSaveTrigger] = useState(0);

  const fileID = props.auth.user.id;
  const isUpdate = !!props.match.params.claimId;

  useEffect(() => {
    if (isUpdate) {
      getClaim(props.match.params.claimId)
        .then(res => {
          setClaim(res.data);
          if (
            res.data.projectAddresses &&
            res.data.projectAddresses.length > 0
          ) {
            setAddressesTable(res.data.projectAddresses);
          }
          if (res.data.files && res.data.files.length > 0) {
            setCurrentFiles(res.data.files);
          }
        })
        .catch(err => console.log(err));
    }

    loadProjects();
  }, [props.match.params.claimId]);

  useEffect(() => {
    if (modified) {
      const saveDraft = setTimeout(() => {
        console.log("TRIGGER SAVE DRAFT");
        // console.log(claim.projectId);
        if (claim.projectId) {
          updateClaimData({ ...claim, autoSave: true }, true);
        }
      }, 2000);

      return () => {
        clearTimeout(saveDraft);
      };
    }
  }, [autoSaveTrigger]);

  const handleChange = (e, target) => {
    let name;
    let value;

    if (target === "projectId" || target === "country") {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    const claimData = { ...claim, [name]: value };
    setClaim(claimData);

    setModified(true);
  };

  const handleChangeProjectData = (e, target) => {
    let name;
    let value;

    if (target === "country") {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setProjectData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const loadProjects = () => {
    getProjects("all")
      .then(res => {
        let projects = [];
        let projectNames = {};
        let projectDetails = {};
        res.data.projects.forEach((value, index) => {
          projects.push({ value: value._id, label: value.projectName });
          projectNames[value._id] = {
            value: value._id,
            label: value.projectName,
          };
          projectDetails[value._id] = value;
        });
        setProjectList(projects);
        setProjectDetails(projectDetails);
        setProjectNames(projectNames);
      })
      .catch(err => console.log(err));
  };

  const handleAddProject = event => {
    event.preventDefault();
    event.stopPropagation();

    if (!projectData.country || projectData.country.length < 2) {
      alert("Choose country.");
      return false;
    }

    setState(prevState => {
      return {
        ...prevState,
        disableAddProjectBtn: true,
      };
    });

    addProject(projectData)
      .then(res => {
        if (res.data.success && res.data.addedProject) {
          createNotification("addedProject");
          loadProjects();
          handleCloseModal();
        } else {
          if (res.data.projectExists) {
            createNotification("projectExists");
          } else if (!res.data.autoSave) {
            createNotification("error", res.data.error);
          }
        }

        setState(prevState => {
          return {
            ...prevState,
            disableAddEditClaimBtn: false,
            disableAddProjectBtn: false,
          };
        });
      })
      .catch(err => console.log(err));
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    if (!claim.projectId || claim.projectId.length < 24) {
      alert("Choose project!");
      return false;
    }

    setState(prevState => {
      return {
        ...prevState,
        disableAddEditClaimBtn: true,
      };
    });

    setTimeout(() => updateClaimData(claim, false), 100);
  };

  const updateClaimData = (claimData, draft) => {
    let data = {
      ...claimData,
      projectAddresses: addressesTable,
      submitReview: submitToReview,
      draft,
    };

    if (uploadedFiles && uploadedFiles.length > 0)
      data.files = [...currentFiles, ...uploadedFiles];

    if (isUpdate) {
      updateClaim(data)
        .then(res => {
          if (res.data.success) {
            if (res.data.updated) createNotification("updated");

            if (res.data.draft && !isUpdate) {
              createNotification("draftSaved");
              history.push(`/claim/edit/${res.data.claimId}`);
            }

            if (res.data.submitted) {
              history.push(`/claims/submitted`);
            }

            if (res.data.updated && !res.data.draft) {
              history.push(`/claims/draft`);
            }
          } else {
            createNotification("error", res.data.error);
          }
        })
        .catch(err => console.log(err));
    } else {
      addClaim(data)
        .then(res => {
          if (res.data.success) {
            if (res.data.draft && !isUpdate) {
              createNotification("draftSaved");
              history.push(`/claim/edit/${res.data.claimId}`);
            }

            if (res.data.newClaim && !res.data.draft) {
              history.push(`/claims/draft`);
            }
          } else {
            createNotification("error", res.data.error);
          }
        })
        .catch(err => console.log(err));
    }

    setState(prevState => {
      return {
        ...prevState,
        disableAddEditClaimBtn: false,
        disableAddProjectBtn: false,
      };
    });
  };

  const handleAddNewAddress = () => {
    const id = Date.now();
    let addresses = [...addressesTable];
    addresses.push({
      id,
      cryptocurrency: "",
      myAddress: "",
      recipientAddress: "",
      amount: "",
    });
    setAddressesTable(addresses);
  };

  const handleRemoveAddress = addressId => {
    let addresses = [...addressesTable];
    const filtered = addresses.filter(
      address => address.id.toString() !== addressId.toString()
    );
    setAddressesTable(filtered);

    // triggerModified();
  };

  const handleChangeAddress = (e, row) => {
    let addresses = [...addressesTable];
    if (!addresses[row]) {
      addresses.push({ [e.target.name]: e.target.value });
    } else addresses[row][e.target.name] = e.target.value;

    setAddressesTable(addresses);

    // triggerModified();
  };

  const triggerModified = () => {
    setAutoSaveTrigger(Date.now());
  };

  const handleOpenModal = target => {
    setState(prevState => {
      return {
        ...prevState,
        [target]: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showProjectModal: false,
        showProjectDataModal: false,
      };
    });
  };

  const disableEdit = status => {
    return status && status !== "draft" && status !== "returned";
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "addedProject":
        NotificationManager.success(
          "You have successfully added the project.",
          "Added Project",
          5000
        );
        break;
      case "updated":
        NotificationManager.success(
          "You have successfully updated the claim.",
          "Claim Update",
          5000
        );
        break;
      case "draftSaved":
        NotificationManager.success(
          "Your changes have been automatically saved.",
          "Auto Save",
          5000
        );
        break;
      case "projectExists":
        NotificationManager.error(
          "Project with that name already exists.",
          "Error",
          5000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  return Object.keys(claim).length > 0 || !isUpdate ? (
    <>
      <Container className="mb-5" fluid>
        <Row>
          <Col>
            <h1 className="mx-md-4">
              Claim
              <Button
                className="blueButton float-right"
                style={{ marginTop: 0 }}
                onClick={() => history.goBack()}
              >
                BACK
              </Button>
            </h1>
          </Col>
        </Row>
        <Row className="mx-md-4 py-3 no-gutters">
          <Col>
            <Form onSubmit={e => handleSubmit(e)}>
              {(claim.status === "returned" || claim.status === "rejected") &&
              claim.comment &&
              claim.comment.length > 0 ? (
                <Form.Group>
                  <Form.Label className="text-warning font-weight-bold">
                    Admin comment
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    maxLength="4000"
                    rows="5"
                    name="comment"
                    defaultValue={claim.comment || ""}
                    disabled={true}
                  />
                </Form.Group>
              ) : null}

              <Form.Group>
                <Form.Label className="font-weight-bold">Fraud Type</Form.Label>
                <Form.Control
                  as="select"
                  name="fraudType"
                  required
                  onBlur={triggerModified}
                  onChange={e => handleChange(e, "")}
                  value={claim.fraudType || ""}
                  disabled={disableEdit(claim.status)}
                >
                  <option value="">Choose Fraud Type</option>
                  {getClaimTypesSelectListOptions()}
                </Form.Control>
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label className="font-weight-bold">
                    Project Name
                  </Form.Label>
                  <Select
                    name="projectId"
                    required
                    onBlur={triggerModified}
                    onChange={e => handleChange(e, "projectId")}
                    options={projectList}
                    value={{
                      label:
                        claim.projectId && projectNames[claim.projectId]
                          ? projectNames[claim.projectId].label
                          : "Select project",
                      value: claim.projectId || "",
                    }}
                    isDisabled={disableEdit(claim.status)}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: "#4B9EC9",
                      },
                    })}
                    className="text-dark"
                  />
                </Form.Group>

                {!disableEdit(claim.status) ? (
                  <Form.Group as={Col} md="6" xs="12">
                    {!isMobileOnly ? (
                      <Form.Label style={{ width: "100%" }}>&nbsp;</Form.Label>
                    ) : null}
                    <Button
                      variant="primary"
                      type="button"
                      className="greenButton mt-0"
                      style={{
                        float: "none",
                        width: "150px",
                        height: "38px",
                      }}
                      onClick={() => handleOpenModal("showProjectModal")}
                    >
                      ADD NEW PROJECT
                    </Button>
                  </Form.Group>
                ) : null}
              </Form.Row>

              {claim.projectId ? (
                <Form.Row>
                  <Form.Group as={Col} md="6" xs="12">
                    <Form.Label className="font-weight-bold">
                      Project URL
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        projectDetails[claim.projectId]
                          ? projectDetails[claim.projectId].projectURL
                          : ""
                      }
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" xs="12">
                    <Form.Label className="font-weight-bold">
                      Country of origin
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        projectDetails[claim.projectId]
                          ? countryList[projectDetails[claim.projectId].country]
                          : "---"
                      }
                      disabled={true}
                    />
                  </Form.Group>
                </Form.Row>
              ) : null}

              <Form.Group>
                <Form.Label className="font-weight-bold">Addresses</Form.Label>

                <Addresses
                  addresses={[...addressesTable]}
                  changeAddress={handleChangeAddress}
                  addNewAddress={handleAddNewAddress}
                  removeAddress={handleRemoveAddress}
                  disableEdit={disableEdit(claim.status)}
                />
              </Form.Group>

              {claim.projectId ? (
                <>
                  <Form.Group>
                    <Form.Label className="font-weight-bold">
                      Project Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength="4000"
                      rows="5"
                      value={
                        projectDetails[claim.projectId]
                          ? projectDetails[claim.projectId].projectDescription
                          : ""
                      }
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="font-weight-bold">
                      Project Scam Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength="4000"
                      rows="5"
                      value={
                        projectDetails[claim.projectId]
                          ? projectDetails[claim.projectId].scamDescription
                          : ""
                      }
                      disabled={true}
                    />
                  </Form.Group>
                </>
              ) : null}

              <Form.Group>
                <Form.Label className="font-weight-bold">
                  My Scam Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength="4000"
                  rows="5"
                  name="scamDescription"
                  placeholder="Describe how you have been scammed."
                  required
                  onBlur={triggerModified}
                  onChange={e => handleChange(e, "")}
                  defaultValue={claim.scamDescription || ""}
                  disabled={disableEdit(claim.status)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Your Estimated Loss (USD)
                </Form.Label>
                <Form.Control
                  type="number"
                  name="estimatedLoss"
                  placeholder="Enter estimated loss in USD"
                  min="0"
                  step="any"
                  required
                  onBlur={triggerModified}
                  onChange={e => handleChange(e, "")}
                  defaultValue={claim.estimatedLoss || ""}
                  disabled={disableEdit(claim.status)}
                />
              </Form.Group>

              {claim.files && claim.files.length > 0 ? (
                <Form.Group>
                  <Form.Label className="font-weight-bold">
                    Uploaded Files:&nbsp;
                  </Form.Label>
                  <div className="d-flex justify-content-start">
                    {claim.files.map((file, idx) => (
                      <FileIcons key={idx} file={file} />
                    ))}
                  </div>
                </Form.Group>
              ) : null}

              {!claim.status ||
              claim.status === "draft" ||
              claim.status === "returned" ? (
                <>
                  <Form.Group>
                    <Form.Label className="font-weight-bold">
                      Upload a File
                    </Form.Label>
                    <FilePond
                      allowMultiple={true}
                      maxFiles={5}
                      onupdatefiles={fileItems => {
                        if (fileItems.length > 0) {
                          let files = [];
                          fileItems.forEach(file => {
                            files.push({
                              type: file.file.type,
                              name: file.file.name,
                            });
                          });
                          if (files.length > 0) {
                            setUploadedFiles(files);
                            triggerModified();
                          }
                        }
                      }}
                      fileRenameFunction={file => {
                        let date = new Date();
                        let time = date.getTime();
                        //prettier-ignore
                        return `${fileID}_${time}_${file.basename}${file.extension}`;
                      }}
                      name={"file"}
                      server={`${API_URL}/upload`}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="greenButton w-auto"
                    onClick={() => setSubmitToReview(true)}
                    disabled={state.disableAddEditClaimBtn}
                  >
                    SUBMIT TO REVIEW
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    className="blueButton w-auto mr-3"
                    disabled={state.disableAddEditClaimBtn}
                  >
                    SAVE CHANGES
                  </Button>
                </>
              ) : null}
            </Form>

            {disableEdit(claim.status) ? (
              <Button
                className="blueButton float-right"
                style={{ marginTop: 0 }}
                onClick={() => history.goBack()}
              >
                BACK
              </Button>
            ) : null}
          </Col>
        </Row>
      </Container>

      <Modal show={state.showProjectModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Project</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleAddProject}>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="font-weight-bold">Project Name</Form.Label>
              <Form.Control
                type="text"
                name="projectName"
                required
                onChange={e => handleChangeProjectData(e, "")}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="font-weight-bold">Project URL</Form.Label>
              <Form.Control
                type="text"
                name="projectURL"
                required
                onChange={e => handleChangeProjectData(e, "")}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="font-weight-bold">
                Project Description
              </Form.Label>
              <Form.Control
                as="textarea"
                maxLength="4000"
                rows="5"
                name="projectDescription"
                placeholder="Describe how the Project was presented to the public."
                required
                onChange={e => handleChangeProjectData(e, "")}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="font-weight-bold">
                Scam Description
              </Form.Label>
              <Form.Control
                as="textarea"
                maxLength="4000"
                rows="5"
                name="scamDescription"
                placeholder="Describe, in your own words, how the fraud happens."
                required
                onChange={e => handleChangeProjectData(e, "")}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="font-weight-bold">
                Country of project origin
              </Form.Label>
              <Select
                name="country"
                required
                onChange={e => handleChangeProjectData(e, "country")}
                options={countryListReactSelect()}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-between">
            <Button
              variant="secondary"
              className="greyButton"
              onClick={handleCloseModal}
            >
              CANCEL
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="blueButton"
              disabled={state.disableAddProjectBtn}
            >
              {state.disableAddProjectBtn ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  SUBMIT
                </>
              ) : (
                "SUBMIT"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={state.showProjectDataModal}
        onHide={handleCloseModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Project Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label className="font-weight-bold">Project Name</Form.Label>
            <Form.Control
              type="text"
              defaultValue={
                claim.projectId && projectDetails[claim.projectId]
                  ? projectDetails[claim.projectId].projectName
                  : "---"
              }
              disabled
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold">Project URL</Form.Label>
            <Form.Control
              type="text"
              defaultValue={
                claim.projectId && projectDetails[claim.projectId]
                  ? projectDetails[claim.projectId].projectURL
                  : "---"
              }
              disabled
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold">
              Project Description
            </Form.Label>
            <Form.Control
              as="textarea"
              maxLength="4000"
              rows="5"
              defaultValue={
                claim.projectId && projectDetails[claim.projectId]
                  ? projectDetails[claim.projectId].projectDescription
                  : "---"
              }
              disabled
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold">
              Scam Description
            </Form.Label>
            <Form.Control
              as="textarea"
              maxLength="4000"
              rows="5"
              defaultValue={
                claim.projectId && projectDetails[claim.projectId]
                  ? projectDetails[claim.projectId].scamDescription
                  : "---"
              }
              disabled
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold">
              Country of project origin
            </Form.Label>
            <Form.Control
              type="text"
              defaultValue={
                claim.projectId && projectDetails[claim.projectId]
                  ? countryList[projectDetails[claim.projectId].country]
                  : "---"
              }
              disabled
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button
            variant="secondary"
            className="greyButton"
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <Container>
      <Row>
        <Col>
          <p className="mt-5">Loading claim...</p>
        </Col>
      </Row>
    </Container>
  );
};

Claims.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(Claims));
