import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { editUserWeb } from "../../../../redux/actions/authActions";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 5) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const acceptClaimDisclosure = document.getElementById(
      "acceptClaimDisclosure"
    ).checked;

    if (
      acceptClaimDisclosure ||
      (!acceptClaimDisclosure &&
        window.confirm(
          "I confirm that I have read ICIFDB Claim Disclosure and understand the risks associated with leveraged Forex and CFDs trading."
        ))
    ) {
      const editUser = {
        step: 5,
        subStep: 1,
        userId: props.auth.user.id,
        acceptClaimDisclosure: true,
      };
      setDisableButton(true);
      props.editUserWeb(editUser);
    }
  };

  return (
    <>
      {isMobileOnly ? (
        <h1 className={classes.h1}>Legal</h1>
      ) : (
        <h1 className={classes.h1}>Claim disclosure</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          {isMobileOnly ? (
            <h2 className={classes.h2}>Claim disclosure</h2>
          ) : null}

          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Text className="pb-3">
                I declare my claim is truthful and I understand my
                responsibility for the information I am adding to ICIFDB.
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Text className="pb-3">
                I understand that my claim can be forwarded to law firms in
                order to be resolved. I also understand that any false claim may
                result with rejection or deletion from ICIFDB or even
                prosecution permitted under law if the claim is false and
                malicious.
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Text className="pb-3">
                I also give consent that third party companies such as law
                firms, claim companies, and others may contact me through ICIFDB
                platform in order to help me to start the process of
                regaining/claiming my funds taken by fraudsters.
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Text className="pb-3">
                I understand I might be a part of class action against
                fraudsters/scammers if group of people have claims against the
                same scammer. I will have the option to decide weather I will
                participate in a class action or not.
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                className="mt-4"
                style={{ maxWidth: "28px" }}
              >
                <Form.Check
                  custom
                  inline
                  label=""
                  type="checkbox"
                  id="acceptClaimDisclosure"
                  checked={userData.acceptClaimDisclosure}
                />
              </Form.Group>
              <Form.Group as={Col} className="mt-4">
                <label
                  className={classes.smallLabel}
                  htmlFor="acceptClaimDisclosure"
                >
                  I confirm I have read ICIFDB Claim Disclosure and understand
                  all the terms and the process of applying to ICIFDB.
                </label>
              </Form.Group>
            </Form.Row>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              variant="link"
              className="greyButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="orangeButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  editUserWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { editUserWeb })(Step);
