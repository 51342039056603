import React, { useEffect } from "react";
import { connect } from "react-redux";
import store from "../../redux/store";
import { Link } from "react-router-dom";
import { setSearchResults } from "../../redux/actions/userActions";
import { Container, Row, Col, Button } from "react-bootstrap";
import SearchResults from "../../components/SearchResults/SearchResults";
import RegisterForReports from "../Pages/Homepage/RegisterForReports/RegisterForReports";

const TextPage = props => {
  const { searchResults } = props.global;

  useEffect(() => {
    store.dispatch(setSearchResults([]));
  }, []);

  return (
    <>
      {searchResults.projects && searchResults.projects.length > 0 ? (
        <>
          <SearchResults searchResults={searchResults} />
          <RegisterForReports />
        </>
      ) : (
        <Container>
          <Row>
            <Col>
              <h1>GDPR / Privacy policy</h1>

              <p>
                This privacy policy applies between you, the User of this
                Website and DILIGIO LTD, the owner and provider of this Website.
                DILIGIO LTD takes the privacy of your information very
                seriously. This privacy policy applies to our use of any and all
                Data collected by us or provided by you in relation to your use
                of the Website.
              </p>
              <p>
                This privacy policy should be read alongside, and in addition
                to, our Terms and Conditions, which can be found at:{" "}
                <Button
                  as={Link}
                  to="/terms-and-conditions"
                  variant="link"
                  className="p-0"
                >
                  https://icifdb.org/terms-and-conditions
                </Button>
                .
              </p>
              <p>
                <strong>Please read this privacy policy carefully.</strong>
              </p>

              <h4>
                <strong>Definitions and interpretation</strong>
              </h4>

              <p>
                1. In this privacy policy, the following definitions are used:
              </p>

              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td width="200">
                      <strong>Data</strong>
                    </td>
                    <td>
                      collectively all information that you submit to DILIGIO
                      LTD via the Website. This definition incorporates, where
                      applicable, the definitions provided in the Data
                      Protection Laws;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Cookies</strong>
                    </td>
                    <td>
                      a small text file placed on your computer by this Website
                      when you visit certain parts of the Website and/or when
                      you use certain features of the Website. Details of the
                      cookies used by this Website are set out in the clause
                      below (<strong>Cookies</strong>);
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Data Protection Laws</strong>
                    </td>
                    <td>
                      any applicable law relating to the processing of personal
                      Data, including but not limited to the Directive 96/46/EC
                      (Data Protection Directive) or the GDPR, and any national
                      implementing laws, regulations and secondary legislation,
                      for as long as the GDPR is effective in the UK;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>GDPR</strong>
                    </td>
                    <td>
                      the General Data Protection Regulation (EU) 2016/679;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>DILIGIO LTD,</strong> or <strong>us</strong>
                    </td>
                    <td>
                      DILIGIO LTD, a company incorporated in England and Wales
                      with registered number 11463151 whose registered office is
                      at Kemp House, 160 City Road, London, EC1V 2NX;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>UK and EU Cookie Law</strong>
                    </td>
                    <td>
                      the Privacy and Electronic Communications (EC Directive)
                      Regulations 2003 as amended by the Privacy and Electronic
                      Communications (EC Directive) (Amendment) Regulations
                      2011;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>User</strong> or <strong>you</strong>
                    </td>
                    <td>
                      any third party that accesses the Website and is not
                      either (i) employed by DILIGIO LTD and acting in the
                      course of their employment or (ii) engaged as a consultant
                      or otherwise providing services to DILIGIO LTD and
                      accessing the Website in connection with the provision of
                      such services; and
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Website</strong>
                    </td>
                    <td>
                      the website that you are currently using,
                      https://icifdb.org, and any sub-domains of this site
                      unless expressly excluded by their own terms and
                      conditions.
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                2. In this privacy policy, unless the context requires a
                different interpretation:
              </p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>the singular includes the plural and vice versa;</li>
                <li>
                  references to sub-clauses, clauses, schedules or appendices
                  are to sub-clauses, clauses, schedules or appendices of this
                  privacy policy;
                </li>
                <li>
                  a reference to a person includes firms, companies, government
                  entities, trusts and partnerships;
                </li>
                <li>
                  "including" is understood to mean "including without
                  limitation";
                </li>
                <li>
                  reference to any statutory provision includes any modification
                  or amendment of it;
                </li>
                <li>
                  the headings and sub-headings do not form part of this privacy
                  policy.
                </li>
              </ul>

              <h4>
                <strong>Scope of this privacy policy</strong>
              </h4>

              <p>
                3. This privacy policy applies only to the actions of DILIGIO
                LTD and Users with respect to this Website. It does not extend
                to any websites that can be accessed from this Website
                including, but not limited to, any links we may provide to
                social media websites.
              </p>
              <p>
                4. For purposes of the applicable Data Protection Laws, DILIGIO
                LTD is the "data controller". This means that DILIGIO LTD
                determines the purposes for which, and the manner in which, your
                Data is processed.
              </p>

              <h4>
                <strong>Data collected</strong>
              </h4>

              <p>
                5. We may collect the following Data, which includes personal
                Data, from you:
              </p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>name;</li>
                <li>
                  contact Information such as email addresses and telephone
                  numbers;
                </li>
                <li>IP address (automatically collected);</li>
                <li>bitcoin addresses entered in search box;</li>
                <li>clicks statistics on the website;</li>
                <li>in each case, in accordance with this privacy policy.</li>
              </ul>

              <h4>
                <strong>How we collect Data</strong>
              </h4>

              <p>6. We collect Data in the following ways:</p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>data is given to us by you; and</li>
                <li>data is collected automatically.</li>
              </ul>

              <h4>
                <strong>Data that is given to us by you</strong>
              </h4>

              <p>
                7. DILIGIO LTD will collect your Data in a number of ways, for
                example:
              </p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  when you contact us through the Website, by telephone, post,
                  e-mail or through any other means;
                </li>
                <li>
                  when you elect to receive marketing communications from us;
                </li>
                <li>when you use our services;</li>
              </ul>

              <p>in each case, in accordance with this privacy policy.</p>

              <h4>
                <strong>Data that is collected automatically</strong>
              </h4>

              <p>
                8. To the extent that you access the Website, we will collect
                your Data automatically, for example:
              </p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  we automatically collect some information about your visit to
                  the Website. This information helps us to make improvements to
                  Website content and navigation, and includes your IP address,
                  the date, times and frequency with which you access the
                  Website and the way you use and interact with its content.
                </li>
                <li>
                  we will collect your Data automatically via cookies, in line
                  with the cookie settings on your browser. For more information
                  about cookies, and how we use them on the Website, see the
                  section below, headed "Cookies".
                </li>
              </ul>

              <h4>
                <strong>Our use of Data</strong>
              </h4>

              <p>
                9. Any or all of the above Data may be required by us from time
                to time in order to provide you with the best possible service
                and experience when using our Website. Specifically, Data may be
                used by us for the following reasons:
              </p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>internal record keeping;</li>
                <li>improvement of our products / services;</li>
                <li>
                  transmission by email of marketing materials that may be of
                  interest to you;
                </li>
                <li>
                  contact for market research purposes which may be done using
                  email, telephone, fax or mail. Such information may be used to
                  customise or update the Website;
                </li>
              </ul>

              <p>in each case, in accordance with this privacy policy.</p>

              <p>
                10. We may use your Data for the above purposes if we deem it
                necessary to do so for our legitimate interests. If you are not
                satisfied with this, you have the right to object in certain
                circumstances (see the section headed "Your rights" below).
              </p>

              <p>
                11. For the delivery of direct marketing to you via e-mail,
                we'll need your consent, whether via an opt-in or soft-opt-in:
              </p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  soft opt-in consent is a specific type of consent which
                  applies when you have previously engaged with us (for example,
                  you contact us to ask us for more details about a particular
                  product/service, and we are marketing similar
                  products/services). Under "soft opt-in" consent, we will take
                  your consent as given unless you opt-out.
                </li>
                <li>
                  for other types of e-marketing, we are required to obtain your
                  explicit consent; that is, you need to take positive and
                  affirmative action when consenting by, for example, checking a
                  tick box that we'll provide.
                </li>
                <li>
                  if you are not satisfied about our approach to marketing, you
                  have the right to withdraw consent at any time. To find out
                  how to withdraw your consent, see the section headed "Your
                  rights" below.
                </li>
              </ul>

              <h4>
                <strong>Who we share Data with</strong>
              </h4>

              <p>
                12. We may share your Data with the following groups of people
                for the following reasons:
              </p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  {" "}
                  our employees, agents and/or professional advisors - to get
                  reports or requested information/advice from our automated
                  service or advisors;
                </li>
              </ul>

              <p>in each case, in accordance with this privacy policy.</p>

              <h4>
                <strong>Keeping Data secure</strong>
              </h4>

              <p>
                13. We will use technical and organisational measures to
                safeguard your Data, for example:
              </p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  access to your account is controlled by a password and a user
                  name that is unique to you.
                </li>
                <li>we store your Data on secure servers.</li>
              </ul>

              <p>
                14. Technical and organisational measures include measures to
                deal with any suspected data breach. If you suspect any misuse
                or loss or unauthorised access to your Data, please let us know
                immediately by contacting us via this e-mail address:{" "}
                <a href="mailto:info@dilig.io">info@dilig.io</a>.
              </p>
              <p>
                15. If you want detailed information from Get Safe Online on how
                to protect your information and your computers and devices
                against fraud, identity theft, viruses and many other online
                problems, please visit www.getsafeonline.org. Get Safe Online is
                supported by HM Government and leading businesses.
              </p>

              <h4>
                <strong>Data retention</strong>
              </h4>

              <p>
                16. Unless a longer retention period is required or permitted by
                law, we will only hold your Data on our systems for the period
                necessary to fulfil the purposes outlined in this privacy policy
                or until you request that the Data be deleted.
              </p>
              <p>
                17. Even if we delete your Data, it may persist on backup or
                archival media for legal, tax or regulatory purposes.
              </p>

              <h4>
                <strong>Your rights</strong>
              </h4>

              <p>18. You have the following rights in relation to your Data:</p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  <strong>Right to access</strong> - the right to request (i)
                  copies of the information we hold about you at any time, or
                  (ii) that we modify, update or delete such information. If we
                  provide you with access to the information we hold about you,
                  we will not charge you for this, unless your request is
                  "manifestly unfounded or excessive." Where we are legally
                  permitted to do so, we may refuse your request. If we refuse
                  your request, we will tell you the reasons why.
                </li>
                <li>
                  <strong>Right to correct</strong> - the right to have your
                  Data rectified if it is inaccurate or incomplete.
                </li>
                <li>
                  <strong>Right to erase</strong> - the right to request that we
                  delete or remove your Data from our systems.
                </li>
                <li>
                  <strong>Right to restrict our use of your Data</strong> - the
                  right to "block" us from using your Data or limit the way in
                  which we can use it.
                </li>
                <li>
                  <strong>Right to data portability</strong> - the right to
                  request that we move, copy or transfer your Data.
                </li>
                <li>
                  <strong>Right to object</strong> - the right to object to our
                  use of your Data including where we use it for our legitimate
                  interests.
                </li>
              </ul>

              <p>
                19. To make enquiries, exercise any of your rights set out
                above, or withdraw your consent to the processing of your Data
                (where consent is our legal basis for processing your Data),
                please contact us via this e-mail address:
                <a href="mailto:info@dilig.io">info@dilig.io</a>.
              </p>
              <p>
                20. If you are not satisfied with the way a complaint you make
                in relation to your Data is handled by us, you may be able to
                refer your complaint to the relevant data protection authority.
                For the UK, this is the Information Commissioner's Office (ICO).
                The ICO's contact details can be found on their website at
                https://ico.org.uk/.
              </p>
              <p>
                21. It is important that the Data we hold about you is accurate
                and current. Please keep us informed if your Data changes during
                the period for which we hold it.
              </p>

              <h4>
                <strong>Links to other websites</strong>
              </h4>

              <p>
                22. This Website may, from time to time, provide links to other
                websites. We have no control over such websites and are not
                responsible for the content of these websites. This privacy
                policy does not extend to your use of such websites. You are
                advised to read the privacy policy or statement of other
                websites prior to using them.
              </p>

              <h4>
                <strong>Changes of business ownership and control</strong>
              </h4>

              <p>
                23. DILIGIO LTD may, from time to time, expand or reduce our
                business and this may involve the sale and/or the transfer of
                control of all or part of DILIGIO LTD. Data provided by Users
                will, where it is relevant to any part of our business so
                transferred, be transferred along with that part and the new
                owner or newly controlling party will, under the terms of this
                privacy policy, be permitted to use the Data for the purposes
                for which it was originally supplied to us.
              </p>
              <p>
                24. We may also disclose Data to a prospective purchaser of our
                business or any part of it.
              </p>
              <p>
                25. In the above instances, we will take steps with the aim of
                ensuring your privacy is protected.
              </p>

              <h4>
                <strong>Cookies</strong>
              </h4>

              <p>
                26. This Website may place and access certain Cookies on your
                computer. DILIGIO LTD uses Cookies to improve your experience of
                using the Website and to improve our range of services. DILIGIO
                LTD has carefully chosen these Cookies and has taken steps to
                ensure that your privacy is protected and respected at all
                times.
              </p>
              <p>
                27. All Cookies used by this Website are used in accordance with
                current UK and EU Cookie Law.
              </p>
              <p>
                28. Before the Website places Cookies on your computer, you will
                be presented with a message bar requesting your consent to set
                those Cookies. By giving your consent to the placing of Cookies,
                you are enabling DILIGIO LTD to provide a better experience and
                service to you. You may, if you wish, deny consent to the
                placing of Cookies; however certain features of the Website may
                not function fully or as intended.
              </p>
              <p>29. This Website may place the following Cookies:</p>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>
                      <strong>Type of Cookie</strong>
                    </td>
                    <td>
                      <strong>Purpose</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Strictly necessary cookies</td>
                    <td>
                      These are cookies that are required for the operation of
                      our website. They include, for example, cookies that
                      enable you to log into secure areas of our website, use a
                      shopping cart or make use of e-billing services.
                    </td>
                  </tr>
                  <tr>
                    <td>Analytical/performance cookies</td>
                    <td>
                      They allow us to recognise and count the number of
                      visitors and to see how visitors move around our website
                      when they are using it. This helps us to improve the way
                      our website works, for example, by ensuring that users are
                      finding what they are looking for easily.
                    </td>
                  </tr>
                  <tr>
                    <td>Functionality cookies</td>
                    <td>
                      These are used to recognise you when you return to our
                      website. This enables us to personalise our content for
                      you, greet you by name and remember your preferences (for
                      example, your choice of language or region).
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                30. You can find a list of Cookies that we use in the Cookies
                Schedule.
              </p>
              <p>
                31. You can choose to enable or disable Cookies in your internet
                browser. By default, most internet browsers accept Cookies but
                this can be changed. For further details, please consult the
                help menu in your internet browser.
              </p>
              <p>
                32. You can choose to delete Cookies at any time; however you
                may lose any information that enables you to access the Website
                more quickly and efficiently including, but not limited to,
                personalisation settings.
              </p>
              <p>
                33. It is recommended that you ensure that your internet browser
                is up-to-date and that you consult the help and guidance
                provided by the developer of your internet browser if you are
                unsure about adjusting your privacy settings.
              </p>
              <p>
                34. For more information generally on cookies, including how to
                disable them, please refer to aboutcookies.org. You will also
                find details on how to delete cookies from your computer.
              </p>

              <h4>
                <strong>General</strong>
              </h4>

              <p>
                35. You may not transfer any of your rights under this privacy
                policy to any other person. We may transfer our rights under
                this privacy policy where we reasonably believe your rights will
                not be affected.
              </p>
              <p>
                36. If any court or competent authority finds that any provision
                of this privacy policy (or part of any provision) is invalid,
                illegal or unenforceable, that provision or part-provision will,
                to the extent required, be deemed to be deleted, and the
                validity and enforceability of the other provisions of this
                privacy policy will not be affected.
              </p>
              <p>
                37. Unless otherwise agreed, no delay, act or omission by a
                party in exercising any right or remedy will be deemed a waiver
                of that, or any other, right or remedy.
              </p>
              <p>
                38. This Agreement will be governed by and interpreted according
                to the law of England and Wales. All disputes arising under the
                Agreement will be subject to the exclusive jurisdiction of the
                English and Welsh courts.
              </p>

              <h4>
                <strong>Changes to this privacy policy</strong>
              </h4>

              <p>
                39. DILIGIO LTD reserves the right to change this privacy policy
                as we may deem necessary from time to time or as may be required
                by law. Any changes will be immediately posted on the Website
                and you are deemed to have accepted the terms of the privacy
                policy on your first use of the Website following the
                alterations.
              </p>

              <p>
                You may contact DILIGIO LTD by email at{" "}
                <a href="mailto:info@dilig.io">info@dilig.io</a>.
              </p>

              <h4>
                <strong>Attribution</strong>
              </h4>

              <p>
                40. This privacy policy was created using a document from Rocket
                Lawyer (https://www.rocketlawyer.co.uk).
              </p>

              <p>
                <strong>02 May 2019</strong>
              </p>

              <h4>
                <strong>Cookies</strong>
              </h4>

              <p>
                Below is a list of the cookies that we use. We have tried to
                ensure this is complete and up to date, but if you think that we
                have missed a cookie or there is any discrepancy, please let us
                know.
              </p>
              <p>Strictly necessary</p>
              <p>We use the following strictly necessary cookies:</p>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>
                      <strong>Description of Cookie</strong>
                    </td>
                    <td>
                      <strong>Purpose</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      We use session cookies to remember you on our website and
                      to enhance your experience.
                    </td>
                    <td>
                      To be able to identify user actions and get technical
                      information needed for our website.
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>Analytical/performance</p>
              <p>We use the following analytical/performance cookies:</p>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>
                      <strong>Description of Cookie</strong>
                    </td>
                    <td>
                      <strong>Purpose</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      We use cookies to collect information about our users
                      behaviour.
                    </td>
                    <td>Collecting user behaviour information.</td>
                  </tr>
                </tbody>
              </table>

              <p>Functionality</p>
              <p>We use the following functionality cookies:</p>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>
                      <strong>Description of Cookie</strong>
                    </td>
                    <td>
                      <strong>Purpose</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      We use this cookie to identify your device and analyse
                      traffic and behaviour patterns on our website.
                    </td>
                    <td>Analysis of traffic and behaviour patterns.</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  global: state.global,
});

export default connect(mapStateToProps)(TextPage);
