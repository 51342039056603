import React, { useEffect } from "react";
import { connect } from "react-redux";
import store from "../../redux/store";
import { setSearchResults } from "../../redux/actions/userActions";
import { Container, Row, Col } from "react-bootstrap";
import SearchResults from "../../components/SearchResults/SearchResults";
import RegisterForReports from "../Pages/Homepage/RegisterForReports/RegisterForReports";

const TextPage = props => {
  const { searchResults } = props.global;

  useEffect(() => {
    store.dispatch(setSearchResults([]));
  }, []);

  return (
    <>
      {searchResults.projects && searchResults.projects.length > 0 ? (
        <>
          <SearchResults searchResults={searchResults} />
          <RegisterForReports />
        </>
      ) : (
        <Container className="pb-5">
          <Row>
            <Col>
              <h1>Mission</h1>

              <p>
                Our mission is to clean out crypto space from all kinds of fraud
                and scams. We aim to make cryptocurrency world a safe place for
                people to work, earn, trade and do business.
              </p>

              <p>
                We believe in teamwork, and we know the power of people coming
                together for a good cause. We know we can make a big change if
                we all stand together and fight for what is right. Let us all
                together be the change we want to see.
              </p>

              <p>
                Our mission is to give hope to all the individuals who; after
                being scammed, feel lost and hopeless. We want to create a place
                of trust whereby working together we minimize crime and
                injustice.
              </p>

              <p>Thank you.</p>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  global: state.global,
});

export default connect(mapStateToProps)(TextPage);
