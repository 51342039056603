import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import classes from "./ReportFraud.module.css";
import { Link } from "react-router-dom";
import bgImage from "../../../../assets/images/section-bg2.jpg";

const reportFraud = () => {
  return (
    <Container
      className={classes.reportFraud}
      style={{ backgroundImage: `url(${bgImage})` }}
      fluid
    >
      <Container className="maxPageWidth" fluid>
        <Row>
          <Col lg={8} />
          <Col lg={4} className="pr-1">
            <h1 className="text-center">Report Crypto Fraud for FREE</h1>
            <h4>We can help you claim your stolen coins.</h4>
            <p className={[classes.p, "pb-5"].join(" ")}>
              You have been scammed and want your cryptocurrency back? Look no
              further, join our Fraud database and file your claim today.
            </p>
            <Button
              type="button"
              variant="primary"
              as={Link}
              to="/register"
              className="bigSignup"
            >
              SIGN UP
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default reportFraud;
