import React, { useEffect } from "react";
import { connect } from "react-redux";
import store from "../../redux/store";
import { setSearchResults } from "../../redux/actions/userActions";
import { Container, Row, Col } from "react-bootstrap";
import SearchResults from "../../components/SearchResults/SearchResults";
import RegisterForReports from "../Pages/Homepage/RegisterForReports/RegisterForReports";

const TextPage = props => {
  const { searchResults } = props.global;

  useEffect(() => {
    store.dispatch(setSearchResults([]));
  }, []);

  return (
    <>
      {searchResults.projects && searchResults.projects.length > 0 ? (
        <>
          <SearchResults searchResults={searchResults} />
          <RegisterForReports />
        </>
      ) : (
        <Container className="pb-5">
          <Row>
            <Col>
              <h1>About Us</h1>

              <p>
                Fraud Database is a unique approach that will help clean up
                crypto space of scams and fraud. Our aim is to fill our data
                base with suspicious/fraud projects and in that way connect our
                clients with lawyers who can help them regain their lost
                cryptocurrencies.
              </p>

              <p>
                When dealing with cryptocurrency assets or projects it is
                especially important to be aware of the risks that can undermine
                your success. Whether it is cybersecurity threat, failure to
                meet compliance criteria or technically unfit platform, your
                efforts could be in danger. We can help you avoid uncanny and
                potentially fatal situations.
              </p>

              <p>
                Diligio provides technology, know-how and consultancy to
                companies and individuals in Crypto and FinTech space. Our
                mission can be described by 3 main and most important goals we
                are striving to achieve:
              </p>

              <ol>
                <li>
                  to provide simple to use yet efficient tools for dealing with
                  different aspects of cryptocurrencies
                </li>
                <li>
                  to support existing legal and technical infrastructure and
                  integrating it in overall crypto structure
                </li>
                <li>
                  to monitor, protect and prevent fraud, crime and any illicit
                  activities across different jurisdictions
                </li>
              </ol>

              <p>
                It might sound complicated but once you start using our tools
                you will be convinced it is not the case.
                <br />
                Company tech team has exceptionally long IT experience (+60
                years combined) in IT and FinTech with proven track record of
                delivery. Diligio has already designed, created and delivered
                several interesting products in area of FinTech and
                Cryptocurrency space.
                <br />
                Our aim is to upscale our infrastructure and number of staff so
                we can provide even better products on a global market.
              </p>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  global: state.global,
});

export default connect(mapStateToProps)(TextPage);
