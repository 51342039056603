import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { claimTypes, countryList } from "../../../config/constants";
import { Col, Container, Row, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { getClaims } from "../../../redux/actions/userActions";
import { cutLongText } from "../../../utils/utils";
import { Link } from "react-router-dom";
import moment from "moment";
import { isMobileOnly } from "react-device-detect";

const Claims = props => {
  const [claims, setClaims] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const { status } = props.match.params;

  useEffect(() => {
    getClaims(status)
      .then(res => {
        setProjectList(res.data.projectList);
        setClaims(res.data.claims);
      })
      .catch(err => console.log(err));
  }, [status]);

  const columns = [
    {
      dataField: "projectName",
      text: "Project Name",
      sort: true,
      classes: "breakWord",
    },
  ];
  if (!isMobileOnly) {
    columns.push(
      {
        dataField: "fraudTypeText",
        text: "Fraud Type",
        sort: true,
        classes: "breakWord",
      },
      {
        dataField: "scamDescriptionCut",
        text: "Scam Description",
        sort: true,
        classes: "breakWord",
      },
      {
        dataField: "country",
        text: "Country of project origin",
        sort: true,
        classes: "breakWord",
      },
      {
        dataField: "timestamp",
        text: "Date Created",
        sort: true,
        classes: "breakWord",
      }
    );
  }

  if (props.match.params.status === "submitted") {
    columns.push({
      dataField: "timeSubmitted",
      text: "Date Submitted",
      sort: true,
      classes: "breakWord",
    });
  }
  if (props.match.params.status === "returned") {
    columns.push({
      dataField: "timeReturned",
      text: "Date Returned",
      sort: true,
      classes: "breakWord",
    });
  }
  if (props.match.params.status === "approved") {
    columns.push({
      dataField: "timeApproved",
      text: "Date Approved",
      sort: true,
      classes: "breakWord",
    });
  }
  if (props.match.params.status === "rejected") {
    columns.push({
      dataField: "timeRejected",
      text: "Date Rejected",
      sort: true,
      classes: "breakWord",
    });
  }
  columns.push({
    dataField: "action",
    text: "",
    sort: false,
  });

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  let data = [];
  if (!!claims) {
    Object.keys(claims).map((keyName, keyIndex) => {
      // Some data formatting before displaying
      claims[keyName].projectName =
        claims[keyName].projectId && projectList[claims[keyName].projectId]
          ? projectList[claims[keyName].projectId].projectName
          : "";

      claims[keyName].fraudTypeText = claimTypes[claims[keyName].fraudType];

      // Cut long descriptions
      claims[keyName].scamDescriptionCut = claims[keyName].scamDescription
        ? cutLongText(claims[keyName].scamDescription, 30)
        : "";

      claims[keyName].country =
        claims[keyName].projectId && projectList[claims[keyName].projectId]
          ? countryList[projectList[claims[keyName].projectId].country]
          : "";

      // Format dates
      if (Number.isInteger(claims[keyName].timestamp)) {
        claims[keyName].timestamp = moment(claims[keyName].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }
      // prettier-ignore
      if (props.match.params.status === "submitted" && Number.isInteger(claims[keyName].timeSubmitted)) {
        claims[keyName].timeSubmitted = moment(claims[keyName].timeSubmitted * 1000).format("YYYY/MM/DD HH:mm:ss").toString();
      }
      // prettier-ignore
      if (props.match.params.status === "returned" && Number.isInteger(claims[keyName].timeReturned)) {
        claims[keyName].timeReturned = moment(claims[keyName].timeReturned * 1000).format("YYYY/MM/DD HH:mm:ss").toString();
      }
      // prettier-ignore
      if (props.match.params.status === "approved" && Number.isInteger(claims[keyName].timeApproved)) {
        claims[keyName].timeApproved = moment(claims[keyName].timeApproved * 1000).format("YYYY/MM/DD HH:mm:ss").toString();
      }
      // prettier-ignore
      if (props.match.params.status === "rejected" && Number.isInteger(claims[keyName].timeRejected)) {
        claims[keyName].timeRejected = moment(claims[keyName].timeRejected * 1000).format("YYYY/MM/DD HH:mm:ss").toString();
      }

      claims[keyName].action = (
        <Button
          as={Link}
          to={`/claim/edit/${claims[keyName]._id}`}
          className="orangeButton orangeButtonSmaller py-0 m-0"
        >
          {status === "draft" || status === "returned" ? "EDIT" : "VIEW"}
        </Button>
      );

      return data.push(claims[keyName]);
    });
  }

  return (
    <>
      <Container className="mb-5" fluid>
        <Row>
          <Col>
            <h1 className="mx-md-4">
              {status} Claims
              <Button
                as={Link}
                to="/claim/add"
                className="blueButton float-right"
                style={{ marginTop: 0 }}
              >
                ADD CLAIM
              </Button>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              hover={true}
              bordered={false}
              headerClasses="tableHeaderClass"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

Claims.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Claims);
