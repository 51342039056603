import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import classes from "./HelpUs.module.css";

const helpUs = () => {
  return (
    <Container className={classes.helpUs} fluid>
      <Container className="maxPageWidth" fluid>
        <Row>
          <Col>
            <div className={classes.headline}>Help us with your entry!</div>
            <div className={classes.subheadline}>
              Together we can make a change
            </div>
            <p className={classes.p}>
              Our mission is to give hope to all the individuals who, after
              being scammed, feel lost and hopeless. We want to create a place
              of trust where by working together we minimize crime and
              injustice.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default helpUs;
