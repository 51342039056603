import React, { Component } from "react";
import { API_URL } from "../../../config/config";
import { Button, Col, Container, Row } from "react-bootstrap";
import setAuthToken from "../../../redux/utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setCurrentUser } from "../../../redux/actions/authActions";
import store from "../../../redux/store";
import { clearLocalStorage } from "../../../utils/utils";

class ConfirmEmail extends Component {
  state = {
    confirming: true,
    emailMessage: "",
  };

  componentDidMount = () => {
    const { id } = this.props.match.params;

    fetch(`${API_URL}/email/confirm/${id}`)
      .then(res => res.json())
      .then(data => {
        if (data.autologin) {
          // Remove registration steps data if exists
          console.log("CLEARING localStorage at email confirm");
          clearLocalStorage();
          // Save to localStorage
          // Set token to localStorage
          const { token } = data;
          localStorage.setItem("jwtToken", token);
          // Set token to Auth header
          setAuthToken(token);
          // Decode token to get user data
          const decoded = jwt_decode(token);
          // Set current user
          store.dispatch(setCurrentUser(decoded));
          localStorage.setItem("userData", JSON.stringify(decoded));

          window.location.href = "/register";
        }

        this.setState({ confirming: false, emailMessage: data.msg });
      })
      .catch(err => console.log(err));
  };

  render = () => (
    <>
      {this.state.confirming ? null : (
        <Container fluid>
          <Row className="justify-content-lg-center">
            <Col lg="6">
              <br />
              <br />
              <p>{this.state.emailMessage}</p>
              <br />
              <br />
              {this.state.emailMessage && this.state.emailMessage.length > 0 ? (
                <Button
                  variant="primary"
                  onClick={() => (window.location.href = "/login")}
                  className="blueButton float-left"
                >
                  LOG IN
                </Button>
              ) : (
                <p>Please wait...</p>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default ConfirmEmail;
