import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { updateCryptoExperience } from "../../../../redux/actions/authActions";
import { FaCheck } from "react-icons/fa";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 3) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleChange = (value, target) => {
    setUserData(prevState => {
      return {
        ...prevState,
        [target]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    setDisableButton(true);

    const tradingExperience = {
      step: 3,
      subStep: 1,
      userId: props.auth.user.id,
      experience: userData.experience,
      ownCryptocurrencies: userData.ownCryptocurrencies,
      interestedInsurance: userData.interestedInsurance,
    };

    props.updateCryptoExperience(tradingExperience);
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Experience</h1>
          <h2 className={classes.h2}>Trading experience</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Trading experience</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col} className="mb-0">
                <Form.Label className={classes.label}>
                  Have you traded Cryptocurrencies before?
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("Yes", "experience")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.experience && userData.experience === "Yes"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxCheck}>
                    <FaCheck className={classes.checkmark} />
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>Yes</span>
                </Form.Text>
              </Form.Group>
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("No", "experience")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.experience && userData.experience === "No"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxCheck}>
                    <span className={classes.bigX}>X</span>
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>No</span>
                </Form.Text>
              </Form.Group>
            </Form.Row>
            <Form.Text style={{ color: "red" }}>{errors.experience}</Form.Text>

            <Form.Row>
              <Form.Group as={Col} className="mt-4 mb-0">
                <Form.Label className={classes.label}>
                  Do you own any Cryptocurrencies?
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("Yes", "ownCryptocurrencies")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.ownCryptocurrencies &&
                    userData.ownCryptocurrencies === "Yes"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxCheck}>
                    <FaCheck className={classes.checkmark} />
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>Yes</span>
                </Form.Text>
              </Form.Group>
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("No", "ownCryptocurrencies")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.ownCryptocurrencies &&
                    userData.ownCryptocurrencies === "No"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxCheck}>
                    <span className={classes.bigX}>X</span>
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>No</span>
                </Form.Text>
              </Form.Group>
            </Form.Row>
            <Form.Text style={{ color: "red" }}>
              {errors.ownCryptocurrencies}
            </Form.Text>

            <Form.Row>
              <Form.Group as={Col} className="mt-4 mb-0">
                <Form.Label className={classes.label}>
                  If you could have a chance to insure your crypto assets would
                  you be interested?
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("Yes", "interestedInsurance")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.interestedInsurance &&
                    userData.interestedInsurance === "Yes"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxCheck}>
                    <FaCheck className={classes.checkmark} />
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>Yes</span>
                </Form.Text>
              </Form.Group>
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("No", "interestedInsurance")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.interestedInsurance &&
                    userData.interestedInsurance === "No"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxCheck}>
                    <span className={classes.bigX}>X</span>
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>No</span>
                </Form.Text>
              </Form.Group>
            </Form.Row>
            <Form.Text style={{ color: "red" }}>
              {errors.interestedInsurance}
            </Form.Text>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              variant="link"
              className="greyButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="orangeButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  updateCryptoExperience: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateCryptoExperience })(Step);
