import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import classes from "./RegisterForReports.module.css";
import bgImage from "../../../../assets/images/section-bg1.jpg";
import sectionImage from "../../../../assets/images/section-img1.png";
import Mailchimp from "react-mailchimp-form";

const RegisterForReports = () => {
  return (
    <Container
      className={classes.registerForReports}
      style={{ backgroundImage: `url(${bgImage})` }}
      fluid
    >
      <Container className="maxPageWidth" fluid>
        <Row>
          <Col lg={8} className="px-0">
            <Image src={sectionImage} fluid />
          </Col>
          <Col lg={4}>
            <div className={classes.headline}>
              Register for <span>reports</span>
            </div>

            <Mailchimp
              action="https://dilig.us19.list-manage.com/subscribe/post?u=2c053da42f80fdc3e0015519a&amp;id=317fb7fdbe"
              // Adding multiple fields:
              fields={[
                {
                  name: "FNAME",
                  placeholder: "First name",
                  type: "text",
                  required: true,
                },
                {
                  name: "LNAME",
                  placeholder: "Last name",
                  type: "text",
                  required: true,
                },
                {
                  name: "EMAIL",
                  placeholder: "Email",
                  type: "email",
                  required: true,
                },
                {
                  name: "PHONE",
                  placeholder: "Phone with country code",
                  type: "text",
                  required: true,
                },
              ]}
              // Change predetermined language
              messages={{
                sending: "Sending...",
                success: "Thank you for your registration!",
                error: "An unexpected internal error has occurred.",
                empty: "Please enter your email in order to proceed.",
                duplicate:
                  "You have already registered with this email address.",
                button: "SUBMIT",
              }}
              // Add a personalized class
              className="mailchimpForm"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default RegisterForReports;
