import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const NewUser = props => (
  <Container className="mb-5" fluid>
    <Row>
      <Col>
        <h1 className="mx-md-4">
          New User
          <Button
            className="blueButton float-right"
            style={{ marginTop: 0 }}
            onClick={() => props.history.goBack()}
          >
            BACK
          </Button>
        </h1>
      </Col>
    </Row>
    <Row className="mx-md-4 py-3 no-gutters">
      <Col>
        Your account has not been approved yet. You can only see project list.
      </Col>
    </Row>
  </Container>
);

export default NewUser;
