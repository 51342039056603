import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import classes from "./StatsBar.module.css";
import { getStats } from "../../redux/actions/userActions";
import { numberFormat } from "../../utils/utils";
import { isMobileOnly } from "react-device-detect";

const StatsBar = () => {
  const [stats, setStats] = useState({});

  useEffect(() => {
    triggerGetStats();

    const userLoader = setInterval(triggerGetStats, 30000);

    // Clean up setInterval
    return () => {
      clearInterval(userLoader);
    };
  }, []);

  const triggerGetStats = () => {
    getStats()
      .then(res => {
        setStats(res.data);
      })
      .catch(err => console.log(err));
  };

  return (
    <Container className={classes.statsBar} fluid>
      <Container className="maxPageWidth" fluid>
        <Row>
          <Col
            className={[
              "px-0 py-2",
              isMobileOnly
                ? "justify-content-center"
                : "d-flex justify-content-between",
            ].join(" ")}
            style={{ flexWrap: "wrap" }}
          >
            <div>
              <span>
                {isMobileOnly ? "Reported:" : "Total reports:"}{" "}
                <span className={classes.highlight}>
                  {stats.reportedClaims
                    ? numberFormat(stats.reportedClaims, 0, "", ",")
                    : 0}
                </span>
              </span>
              <span>
                {isMobileOnly ? "Scam projects:" : "Suspicious/scam projects:"}{" "}
                <span className={classes.highlight}>
                  {stats.approvedProjects
                    ? numberFormat(stats.approvedProjects, 0, "", ",")
                    : 0}
                </span>
              </span>
              {isMobileOnly ? <br /> : null}
              <span>
                {"Total loss: "}
                <span className={classes.highlight}>
                  $
                  {stats.totalLoss
                    ? numberFormat(stats.totalLoss, 2, ".", ",")
                    : ""}
                </span>
              </span>
              <span>
                {"Users: "}
                <span className={classes.highlight}>
                  {stats.totalUsers
                    ? numberFormat(stats.totalUsers, 0, "", ",")
                    : ""}
                </span>
              </span>
              {/*<span>*/}
              {/*  Last 24h loss: <span className={classes.highlight}>42.291</span>*/}
              {/*</span>*/}
              {/*<span>*/}
              {/*  Weekly avg loss: <span className={classes.highlight}>42.291</span>*/}
              {/*</span>*/}
            </div>

            {isMobileOnly ? <div className="break" /> : null}

            {/* <!-- Go to www.addthis.com/dashboard to customize your tools -->*/}
            <div
              className="addthis_inline_share_toolbox"
              style={isMobileOnly ? { float: "left" } : {}}
            />

            {isMobileOnly ? <div className="break" /> : null}

            <div>
              <Button
                type="button"
                onClick={() => (window.location.href = "/login")}
                className={classes.login}
              >
                LOG IN
              </Button>
              <Button
                variant="link"
                type="button"
                onClick={() => (window.location.href = "/register")}
                className={classes.register}
              >
                Register
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default StatsBar;
