import React from "react";
import { API_URL } from "../../config/config";
import { FiFile, FiFileText } from "react-icons/fi";
import { GrDocumentPdf, GrDocumentWord } from "react-icons/gr";
import { ImFileZip } from "react-icons/im";
import { SiMicrosoftexcel } from "react-icons/si";

const FileIcons = props => {
  const { file } = props;

  let IconWrapper;
  switch (file.type) {
    case "text/plain":
      IconWrapper = FiFileText;
      break;
    case "application/zip":
    case "application/x-7z-compressed":
    case "application/vnd.rar":
      IconWrapper = ImFileZip;
      break;
    case "application/pdf":
      IconWrapper = GrDocumentPdf;
      break;
    case "application/msword":
      IconWrapper = GrDocumentWord;
      break;
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      IconWrapper = SiMicrosoftexcel;
      break;
    default:
      IconWrapper = FiFile;
  }

  return (
    <a
      href={`${API_URL}/public/files/${file.name}`}
      target="_blank"
      rel="noopener noreferrer"
      className="fileIcons"
    >
      {file.type.includes("image/") ? (
        <img
          src={`${API_URL}/public/files/${file.name}`}
          alt={file.name}
          className="image"
        />
      ) : (
        <IconWrapper />
      )}
    </a>
  );
};

export default FileIcons;
