import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import Particles from "react-particles-js";
import classes from "./FooterHomepage.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-white">
      <Particles
        className={classes.particlesJS}
        params={{
          particles: {
            color: {
              value: "#4d6f85",
            },
            line_linked: {
              color: "#4D6F85",
            },
          },
        }}
      />

      <Container className="pt-4 pt-md-5 maxPageWidth" fluid>
        <Row>
          <Col className={classes.footerColumn}>
            <h5 className={classes.h5}>FraudDB</h5>
            <ul className="list-unstyled text-small">
              <li>
                <Button
                  variant="link"
                  as={Link}
                  to="/about-us"
                  className="pl-0 text-white text-left"
                >
                  ABOUT US
                </Button>
              </li>
              <li>
                <Button
                  variant="link"
                  as={Link}
                  to="/mission"
                  className="pl-0 text-white text-left"
                >
                  MISSION
                </Button>
              </li>
              <li>
                <Button
                  variant="link"
                  as={Link}
                  to="/how-can-we-help"
                  className="pl-0 text-white text-left"
                >
                  HOW CAN WE HELP
                </Button>
              </li>
            </ul>
          </Col>
          <Col className={classes.footerColumn}>
            <h5 className={classes.h5}>Legal info</h5>
            <ul className="list-unstyled text-small">
              <li>
                <Button
                  variant="link"
                  as={Link}
                  to="/terms-and-conditions"
                  className="pl-0 text-white text-left"
                >
                  Terms and Conditions
                </Button>
              </li>
              <li>
                <Button
                  variant="link"
                  as={Link}
                  to="/gdpr-privacy-policy"
                  className="pl-0 text-white text-left"
                >
                  GDPR / Privacy policy
                </Button>
              </li>
            </ul>
          </Col>
          <Col className={classes.footerColumn}>
            <h5 className={classes.h5}>Contact</h5>
            <ul className="list-unstyled text-small">
              <li className="text-white">DILIGIO LTD</li>
              <li className="text-white">Kemp House</li>
              <li className="text-white">160 City Road</li>
              <li className="text-white">London, EC1V 2NX</li>
              <li className="text-white">United Kingdom</li>
            </ul>
          </Col>
        </Row>
        <Row
          className={[classes.copyright, "d-block mt-4 pt-2 text-center"].join(
            " "
          )}
        >
          Copyright &copy; by DILIGIO LTD
          <br />
          DILIGIO LTD is a company registered in England and Wales under company
          number 11463151
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
